/* 
  Documentation:
  It requires 2 params to work
  1. filterBy 2. selectedID

  Gets Quotes using filterBy
  Then sets selectedQuote by going through data received
  having same id as selectedID in param.

 */
// import PrintProductPdf from "components/ProductPdfPrintButton/PrintProductPdf";
// import WOOrderEmail from "components/QuoteOrderEmail/WOOrderEmail";
import QuotePdfButton from "components/Pdfs/QuotePdf/QuotePdfButton";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import ManageQuotes from "./ManageQuote/ManageQuotes";
import ImportBid from "./QuoteBid/ImportBid";
import QuoteBid from "./QuoteBid/QuoteBid";
import QuoteFiles from "./QuoteFiles/QuoteFiles";
import useWorkOrder from "features/Contexts/WorkOrderContext";

function QuoteDetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setCustomerData } = useWorkOrder();
  const params = useParams();
  /*   ALL STATES
   ********************************************* */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchUrl, setSearchUrl] = useState("");

  const [active, setActive] = useState("quoteDetails");
  const [loading, setLoading] = useState(false);
  const [allQuotesData, setAllQuotesData] = useState(null);

  const [quoteType, setQuoteType] = useState(null);
  const [status, setStatus] = useState(null);

  const [selectedQuote, setSelectedQuote] = useState(null);

  const [formData, setFormData] = useState({});

  /*   ALL FUNCTIONS
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const checkActive = (val) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("tab", val);
      return newParams.toString();
    });
  };

  const getQuotes = async () => {
    setLoading(true);
    try {
      let activeTab = params?.filterBy;
      let currentID = params?.selectedID;

      let page = searchParams?.get("page");
      let query = searchParams?.get("query");

      let body = { page, activeTab };

      if (query) {
        body.query = query;
      }

      const { data } = await API.post("/quotes", body, {
        headers: authHeader(),
      });
      setAllQuotesData(data.result);
      let matchedOrderById = data?.result?.find(
        (item) => item?.id === parseInt(currentID)
      );
      if (matchedOrderById) {
        setSelectedQuote(matchedOrderById);
        setLoading(false);
        return data.result;
      } else {
        let quoteById = await getQuoteById(currentID);
        setSelectedQuote(quoteById);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const getQuoteById = async (id) => {
    setLoading(true);
    try {
      const { data } = await API.get("/quotes/" + id, {
        headers: authHeader(),
      });
      return data?.result;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const updateQuoteData = async (e) => {
    e.preventDefault();
    if (!formData?.jobName) {
      dispatch(
        setNotification({
          type: "error",
          message: "Please fill in the job name field",
        })
      );
      return;
    }
    setLoading(true);

    try {
      const { data } = await API.patch(
        "/quotes",
        {
          ...formData,
          status: status,
          id: selectedQuote.id,
        },
        { headers: authHeader() }
      );

      if (data.type === "success") {
        await getQuotes();

        setLoading(false);
        return;
      }

      dispatch(
        setNotification({
          message: "check logs",
          type: "error",
        })
      );
      console.log(data);

      setLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const updateBidId = async (id) => {
    try {
      loadingOn();
      let patchBody = {
        id: selectedQuote?.id,
        bidId: id,
      };
      const { data } = await API.patch("/quotes", patchBody, {
        headers: authHeader(),
      });
      await getQuotes();
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const prevQuote = () => {
    let curQuoteId = params.selectedID;

    let checkCrrIdExists = allQuotesData?.find(
      (item) => item?.id === parseInt(curQuoteId)
    );

    if (!checkCrrIdExists) {
      return;
    }
    let currentIndex = allQuotesData?.indexOf(selectedQuote);
    if (currentIndex === 0) {
      return;
    }
    let newId = allQuotesData[currentIndex - 1]?.id;
    navigate(`/quotes/details/${params?.filterBy}/${newId}/${searchUrl}`);
  };

  const nextQuote = () => {
    let curQuoteId = params.selectedID;
    let checkCrrIdExists = allQuotesData?.find(
      (item) => item?.id === parseInt(curQuoteId)
    );
    if (!checkCrrIdExists) {
      return;
    }
    let currentIndex = allQuotesData?.indexOf(selectedQuote);
    let totalLength = allQuotesData?.length;
    if (currentIndex === totalLength - 1) {
      return;
    }
    let newId = allQuotesData[currentIndex + 1]?.id;
    navigate(`/quotes/details/${params?.filterBy}/${newId}/${searchUrl}`);
  };

  const handleChangeType = async (e) => {
    let selectedType = e.target.value;

    let bidId = selectedQuote?.bidId;

    if (selectedQuote?.bidId && selectedType === "Quote") {
      await removeBid(bidId);
    }
    if (!selectedQuote?.bidId && selectedType === "Bid") {
      await createBid();
    }
    setQuoteType(selectedType);
  };

  const createBid = async () => {
    try {
      loadingOn();

      let body = {
        customerName: selectedQuote?.customer?.customerName,
        customerId: selectedQuote?.customer?.id,
        bidStatus: "pending",
        quoteId: selectedQuote?.id,
      };
      const { data } = await API.post(
        "/bids",
        { data: body },
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "success") {
        getQuotes();
        loadingOff();
        return;
      }
      console.log(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const removeBid = async (id) => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/bids/delete",
        { id, quoteId: selectedQuote?.id },
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "success") {
        getQuotes();
        loadingOff();
        return;
      }
      console.log(data);
      loadingOff();
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleBack = () => {
    searchParams.delete("tab");
    navigate(`/quotes/${params?.filterBy}?${searchParams.toString()}`);
  };

  const handleViewCustomer = () => {
    setCustomerData({ id: selectedQuote?.customerId });
    navigate("/customer");
  };

  const getSearchParams = () => {
    let paramsUrl = "";
    searchParams.forEach((value, key) => {
      paramsUrl += `${key}=${value}&`;
    });

    setSearchUrl(`?${paramsUrl.slice(0, -1)}`);
    return `?${paramsUrl.slice(0, -1)}`;
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedQuote) {
      setStatus(selectedQuote.status);
    }
  }, [selectedQuote]);

  useEffect(() => {
    if (
      params?.filterBy &&
      params?.selectedID &&
      selectedQuote?.id !== parseInt(params?.selectedID)
    ) {
      getQuotes();
    }
  }, [params]);

  useEffect(() => {
    if (selectedQuote) {
      let type = selectedQuote?.bidId ? "Bid" : "Quote";
      setQuoteType(type);
    }
  }, [selectedQuote]);

  useEffect(() => {
    getSearchParams();
    let tab = searchParams.get("tab");
    let tabList = {
      "quote-details": "quoteDetails",
      "bid-details": "bidDetails",
      "quote-products": "quoteProducts",
      files: "files",
    };

    if (tab) {
      setActive(tabList[tab]);
    } else {
      setSearchParams((prev) => {
        return [...prev, ["tab", "quote-details"]];
      });
      setActive("quote-details");
    }
  }, [searchParams]);

  return (
    <div
      className="d-flex flex-grow-1 "
      style={{
        borderRadius: "10px",
        position: "fixed",
        top: 0,
        width: "calc(100vw - 180px)",
        paddingLeft: 80,
        marginLeft: 180,
        zIndex: 101,
      }}
    >
      {loading && <Spinner />}
      <div className="d-flex flex-grow-1 flex-column">
        <div className="ps-3 bg-white shadow-lg py-2 pe-3 d-flex justify-content-between align-items-center ">
          <div className="">
            <div className="mb-1">
              Quote ID : {selectedQuote?.id}&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="font-weight-bold h5">
                {selectedQuote?.jobName?.toUpperCase()}
              </span>
            </div>
            <div>
              <button
                onClick={handleViewCustomer}
                className="btn btn-sm btn-info me-2 py-0 px-1 mb-1 "
              >
                View Customer
              </button>
              <span className="mt-0">
                Customer ID :&nbsp;
                {selectedQuote?.customer?.customerId}&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font-weight-bold h5">
                  {selectedQuote?.customer?.customerName?.toUpperCase()}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font-weight-bold h5">
                  PO Required: &nbsp;
                  {typeof selectedQuote?.customer?.poRequired === "boolean"
                    ? selectedQuote?.customer?.poRequired
                      ? "Yes"
                      : "No"
                    : "No Set"}
                </span>
              </span>
            </div>
          </div>
          <div className="d-flex gap-1 justify-content-end align-items-center ">
            <h5 className="text-nowrap me-2">Type</h5>
            <select
              value={quoteType}
              onChange={handleChangeType}
              className="form-select"
              id="commissionType"
            >
              <option value={"Quote"}>Quote</option>
              <option value={"Bid"}>Bid</option>
            </select>
            <h5 className="text-nowrap me-2">Status</h5>
            <select
              onChange={(e) => setStatus(e.target.value)}
              value={status || ""}
              className="form-select"
              id="commissionType"
            >
              <option value={"pending"}>Pending</option>
              <option value={"processing"}>Processing</option>
              <option value={"completed"}>Completed</option>
            </select>

            <QuotePdfButton
              btnClasses={"btn btn-success text-nowrap"}
              selectedQuote={selectedQuote}
            />

            {(active === "quoteDetails" || active === "bidDetails") && (
              <button onClick={updateQuoteData} className="btn btn-success">
                Save
              </button>
            )}
            {selectedQuote?.bidId && (
              <ImportBid
                refreshFunc={getQuotes}
                selectedQuote={selectedQuote}
                updateBidId={updateBidId}
                updateQuoteData={updateQuoteData}
              />
            )}
            <button onClick={handleBack} className={"btn btn-danger "}>
              BACK
            </button>
            <button
              onClick={() => {
                prevQuote();
              }}
              className={"btn btn-success btn-sm p-0 px-1"}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextQuote();
              }}
              className={"btn btn-success btn-sm p-0 px-1 "}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>
          </div>
        </div>

        <div className="d-flex flex-grow-1">
          <div className="flex-grow-1  ">
            <div className="bg-white mx-2 mt-2 custom-border-radius-sm custom-shadow px-3 d-flex justify-content-between align-items-center">
              <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1 py-2">
                <li
                  onClick={() => {
                    if (selectedQuote?.jobName) {
                      checkActive("quote-details");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "quoteDetails"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Quote Details</span>
                  </button>
                </li>

                <li
                  onClick={() => {
                    if (selectedQuote?.jobName) {
                      checkActive("bid-details");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "bidDetails"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Bid Details</span>
                  </button>
                </li>

                <li
                  onClick={() => {
                    if (selectedQuote?.jobName) {
                      checkActive("files");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "files"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Quote Files</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="px-2 mt-2">
              {active === "quoteDetails" && (
                <div className="tab-pane show active">
                  {selectedQuote && (
                    <ManageQuotes
                      formData={formData}
                      setFormData={setFormData}
                      refreshFunc={getQuotes}
                      selectedQuote={selectedQuote}
                    />
                  )}
                </div>
              )}

              {selectedQuote?.bidId && active === "bidDetails" && (
                <div className="tab-pane show active relative">
                  <QuoteBid
                    refreshFunc={getQuotes}
                    formData={formData}
                    setFormData={setFormData}
                    selectedQuote={selectedQuote}
                  />
                </div>
              )}
              {active === "files" && (
                <div className="tab-pane show active overflow-auto">
                  {selectedQuote?.customer && (
                    <QuoteFiles selected={selectedQuote} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteDetailsPage;
