import axios from "axios";
import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import CustomerAdd from "./CustomerAdd";
import CustomerInfo from "./CutomerDetails/CustomerInfo";
import ImportCustomersExcel from "./ImportCustomersExcel";
import AdvanceSearchForm from "./AdvanceSearch/AdvanceSearchForm";

function Customers() {
  const user = useSelector((state) => state.user?.userInfo);
  const { customerContextData } = useWorkOrder();

  /*   All States
   ********************************************* */

  const [allCustomersData, setAllCustomersData] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [searchedText, setSearchedText] = useState(null);

  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [filterBy, setFilterBy] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [advanceSearchModal, setAdvanceSearchModal] = useState(false);

  const [showImportForm, setShowImportForm] = useState(false);

  /*   All Function
   ********************************************* */
  const getAllCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setAllCustomersData(data);
      return data;
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!customerContextData) getAllCustomersData();
  }, [filterBy, showDetailsPage]);

  useEffect(() => {
    if (customerContextData) {
      setSelectedId(customerContextData?.id);
      setShowDetailsPage(true);
    }
  }, [customerContextData]);

  const downloadSampleFile = () => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: "kms/customer/Customers Data - Sample.xlsx",
      })
      .then((response) => {
        const url = response.data[0];
        const link = document.createElement("a");
        link.href = url;
        link.download = "Customers Data - Sample.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("customer_add") ||
        user?.roles === "admin") && (
        <div className="d-flex justify-content-center align-items-center gap-1">
          <button
            onClick={() => setAdvanceSearchModal(true)}
            type="button"
            className="btn px-2 btn-success ms-1"
          >
            Advance Search
          </button>
          <button
            onClick={() => setShowImportForm(true)}
            type="button"
            className="btn px-2 btn-success"
          >
            IMPORT FILE
          </button>
          <button
            onClick={() => downloadSampleFile()}
            type="button"
            className="btn px-2 btn-info"
          >
            <i className="mdi mdi-download"></i> SAMPLE FILE
          </button>
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn px-2 btn-primary"
          >
            ADD CUSTOMER
          </button>
        </div>
      )}
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("customerName")}
          className="dropdown-item"
        >
          Customer Name
        </button>
        <button
          onClick={() => setFilterBy("accountPayableContact")}
          className="dropdown-item"
        >
          Payable Name
        </button>
        <button
          onClick={() => setFilterBy("accountPayablePhone")}
          className="dropdown-item"
        >
          Payable Phone
        </button>
        <button
          onClick={() => setFilterBy("accountPayableEmail")}
          className="dropdown-item"
        >
          Payable Email
        </button>
        <button
          onClick={() => setFilterBy("customerType")}
          className="dropdown-item"
        >
          Customer Type
        </button>
      </div>
    </div>
  );
  const table_head = [
    "ID",
    "Customer Name",
    "Payable Account Name",
    "Payable Account Phone",
    "Payable Account Email",
    "location",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.customerId}>
      <td className="py-1 align-middle">{item?.customerId}</td>
      <td
        className="py-1 align-middle"
        onDoubleClick={() => {
          setShowDetailsPage(true);
          setSelectedId(item.id);
        }}
      >
        {item?.customerName}
      </td>
      <td className="py-1 align-middle">{item?.accountPayableContact}</td>
      <td className="py-1 align-middle">{item?.accountPayablePhone} </td>
      <td className="py-1 align-middle">{item?.accountPayableEmail}</td>
      <td className="py-1 align-middle">{item?.location?.name}</td>
      <td className="table-action py-1 align-middle">
        <button
          onClick={() => {
            setShowDetailsPage(true);
            setSelectedId(item.id);
          }}
          className={`btn btn-sm bg-success text-white mx-2 `}
        >
          Details
        </button>
      </td>
    </tr>
  );

  if (!allCustomersData && !showDetailsPage) return <Spinner />;

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex ">
          {/*    Table Card
           ********************************************* */}
          {!showDetailsPage ? (
            <div
              className="p-4 pb-2 flex-grow-1 rounded d-flex"
              style={{ marginLeft: 260 }}
            >
              {allCustomersData && (
                <div className="horizontal-scrollable py-2 d-flex flex-grow-1">
                  <Table
                    filterBy={filterBy}
                    buttons={buttons}
                    btn1={btn1}
                    table_head={table_head}
                    table_body={table_body}
                    table_data={searchResult ? searchResult : allCustomersData}
                    searchResults={setSearchResult}
                    searchedText={searchedText}
                    setSearchedText={setSearchedText}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex-grow-1">
              <CustomerInfo
                searchResult={searchResult}
                selectedId={selectedId}
                setSearchResult={setSearchResult}
                setShowDetailsPage={setShowDetailsPage}
              />
            </div>
          )}
        </div>
      </div>
      {/*   Add Modal
       ********************************************* */}
      <CustomerAdd
        customersData={allCustomersData}
        refreshFunc={getAllCustomersData}
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        setAllCustomersData={setAllCustomersData}
        setSelectedId={setSelectedId}
        setShowDetailsPage={setShowDetailsPage}
      />

      {/*   Import Excel Modal
       ********************************************* */}
      {allCustomersData && (
        <Modal
          size="lg"
          contentClassName="bg-transparent"
          show={showImportForm}
          onHide={() => setShowImportForm(false)}
        >
          <Modal.Body
            className="bg-white shadow p-3"
            style={{ borderRadius: 15 }}
          >
            <ImportCustomersExcel
              allCustomersData={allCustomersData}
              refreshFunc={getAllCustomersData}
              setShowImportForm={setShowImportForm}
            />
          </Modal.Body>
        </Modal>
      )}
      {/*   Advance Customer Search
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={advanceSearchModal}
        onHide={() => setAdvanceSearchModal(false)}
      >
        <Modal.Body
          className="bg-light shadow p-3"
          style={{ borderRadius: 15 }}
        >
          <AdvanceSearchForm
            showModal={setAdvanceSearchModal}
            setSearchResult={setSearchResult}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Customers;
