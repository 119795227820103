import { PDFViewer } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";
import { ProductPdf } from "./WorkOrders/WorkOrderDetails/WOProducts/ProductPdf";
import { QuotePdf } from "components/Pdfs/QuotePdf/QuotePdf";
import { TeamViewPdf } from "components/Pdfs/TeamViewPdf/TeamViewPdf";
import { WOPdf } from "components/Pdfs/WOPdf/WOPdf";

const data = [
  {
    id: 5506,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 1,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "White",
    productDescription: "Twill Unstructured Cap",
    productFabric: "Brushed Cotton",
    productSizes:
      '[{"size":"OS","quantity":"3","price":0,"cost":0,"id":"18fca91b-1bf3-4e5f-8598-6a90441a5376","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/Blue%20Whale%20Polo%20%26%20Hat%202024%203.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=c50db1839cd090891e7312e089f03a52be7d350df50706b40a6fb48c8c06e1cf&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 3,
    totalPrice: "0",
    notes: "<p>Whale Logo</p><p>Embroidery Color: Royal</p>",
    isReady: true,
    createdAt: "2024-07-06T17:17:30.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5507,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 2,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "Black",
    productDescription: "Twill Unsctructured Cap",
    productFabric: "Brushed Cotton",
    productSizes:
      '[{"size":"OS","quantity":"2","price":0,"cost":0,"id":"8941aa62-646d-4834-b096-f43afde0c017","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%204.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=f79cb931ea7edf61165fa0017259ed05c6b4718542675c39510bff0de13e37fd&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 2,
    totalPrice: "0",
    notes: "<p>Whale Logo</p><p>Embroidery Color: Royal</p>",
    isReady: true,
    createdAt: "2024-07-06T17:18:12.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5508,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 3,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "Navy",
    productDescription: "Twill unstructured Cap",
    productFabric: "Brushed Cotton",
    productSizes:
      '[{"size":"OS","quantity":"4","price":0,"cost":0,"id":"8b21935a-3f78-4d2e-bb0b-2599cc38e6e6","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%205.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=8ef52547c48630f1d2df5a1d5429d3c470486660237302af9e7607f51149140e&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 4,
    totalPrice: "0",
    notes: "<p>Whale Logo</p><p>Embroidery Color: Royal</p>",
    isReady: true,
    createdAt: "2024-07-06T17:18:56.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5509,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 4,
    productType: "Embroidery",
    productStyle: "DG150",
    productColor: "Navy",
    productDescription: "Polo shirt",
    productFabric: "Cotton ",
    productSizes:
      '[{"size":"AM","quantity":"4","price":0,"cost":0,"id":"fc6a54f0-0004-49f8-b991-4b298d817abc","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/Blue%20Whale%20Polo%20%26%20Hat%202024%20new%202.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=641aafe5d3af3975914e8c738c1a4c4a232d541a105935c133b9d72a7bb83033&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 4,
    totalPrice: "0",
    notes: "",
    isReady: true,
    createdAt: "2024-07-06T17:20:50.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5510,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 5,
    productType: "Embroidery",
    productStyle: "DG150",
    productColor: "White",
    productDescription: "Polo Shirt",
    productFabric: "Cotton ",
    productSizes:
      '[{"size":"AM","quantity":"2","price":0,"cost":0,"id":"ad0593e2-577c-4f4e-a009-c30fb7f57b2b","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/Blue%20Whale%20Polo%20%26%20Hat%202024%20new%201.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=6c4aa9e99d02695659bb7f200c8d5cf7404283cd1c98392fde6bb8906ca7fd52&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 2,
    totalPrice: "0",
    notes: "",
    isReady: true,
    createdAt: "2024-07-06T17:21:13.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5514,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 8,
    productType: "Embroidery",
    productStyle: "DG150",
    productColor: "Grey Heather",
    productDescription: "Polo Shirt",
    productFabric: "Cotton ",
    productSizes:
      '[{"size":"AM","quantity":"1","price":0,"cost":0,"id":"1a4340aa-c6dc-4a4b-a0ad-d52597811540","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/Blue%20Whale%20Polo%20%26%20Hat%202024%20new%203.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=d0e539d3c8118cd8ba7593b99c914341f787135544560fed40b4319d379232da&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 1,
    totalPrice: "0",
    notes: "",
    isReady: true,
    createdAt: "2024-07-06T17:28:27.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5734,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 9,
    productType: "Screen Printing",
    productStyle: ".",
    productColor: "White",
    productDescription: "White T-shirt",
    productFabric: "Cotton",
    productSizes:
      '[{"size":"AL","quantity":"6","price":0,"cost":0,"id":"b6a7a153-4905-4870-91c6-52d5d37bf6ae","sortId":1},{"size":"AXL","quantity":"6","price":0,"cost":0,"id":"d50d660f-b86f-46ef-9e0a-2dcd0c9f8ddb","sortId":2}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/Blue%20Whale%20Polo%20%26%20Hat%202024%20shrt.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=67c485dada8f7bae908555f2f75ed752b2202cee14dd383c70231d4fd54c7fe3&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 12,
    totalPrice: "0",
    notes: "<p>Customer Brought own shirts.</p><p>Royal Blue, Red</p>",
    isReady: false,
    createdAt: "2024-07-13T02:33:56.000Z",
    updatedAt: "2024-07-22T16:49:07.000Z",
  },
  {
    id: 5515,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 11,
    productType: "Embroidery",
    productStyle: "DG150",
    productColor: "Graphite",
    productDescription: "Polo Shirt",
    productFabric: "Cotton ",
    productSizes:
      '[{"size":"AM","quantity":"1","price":0,"cost":0,"id":"1a644baa-ccba-4723-94d7-a6fb8075f6aa","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/Blue%20Whale%20Polo%20%26%20Hat%202024%20new%204.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=ff08baef001151bdb4c7e2fac0e7ff6f97d70d1469e0712ec7f63cfa16abe1d4&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 1,
    totalPrice: "0",
    notes: "",
    isReady: true,
    createdAt: "2024-07-06T17:28:48.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5517,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 12,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "Navy",
    productDescription: "Twill Unstructured Hat",
    productFabric: "Brushed Cotton",
    productSizes:
      '[{"size":"OS","quantity":"1","price":0,"cost":0,"id":"853201dd-75f3-4f1e-ae02-e9051135d223","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%203.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=d1090e4c4b523098f138e223cbcfa65c419750add5e961d560116bae3f36aa83&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 1,
    totalPrice: "0",
    notes: "<p>DAD</p><p>EST. 2006</p><p>Royal EMBROIDERY THREAD</p>",
    isReady: true,
    createdAt: "2024-07-06T17:48:32.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5518,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 13,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "Black",
    productDescription: "Twill Unstructured Hat",
    productFabric: "Brushed Cotton",
    productSizes:
      '[{"size":"OS","quantity":"1","price":0,"cost":0,"id":"853201dd-75f3-4f1e-ae02-e9051135d223","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%202.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=2f80adf9d3e1e3897d2e15963438f841ec6d0a4d165c3213ebff761e49ff1d07&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 1,
    totalPrice: "0",
    notes: "<p>DAD</p><p>EST. 2006</p><p>WHITE EMBROIDERY THREAD</p>",
    isReady: true,
    createdAt: "2024-07-06T17:49:36.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5822,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 14,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "Navy",
    productDescription: "Hat",
    productFabric: ".",
    productSizes:
      '[{"size":"OS","quantity":"2","price":0,"cost":0,"id":"47ac50f4-f8b5-4f07-a8dd-5f3fd5d1b6ec","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%20side2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=a9a71c3d40fa90deb3dbda41e605eeba16a3d48d9e244cc35c6b088aa2a114e9&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 2,
    totalPrice: "0",
    notes: '<p>" Blue Whale Pool Pro.com " on side</p>',
    isReady: true,
    createdAt: "2024-07-18T17:43:55.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5823,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 15,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "Black",
    productDescription: "Hat",
    productFabric: ".",
    productSizes:
      '[{"size":"OS","quantity":"2","price":0,"cost":0,"id":"47ac50f4-f8b5-4f07-a8dd-5f3fd5d1b6ec","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%20side.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=bb16ea3841dcb6fa413cd1cfb7d0512f27c89827da503088bbb49c34fde7de41&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 2,
    totalPrice: "0",
    notes: '<p>" Blue Whale Pool Pro.com " on side</p>',
    isReady: true,
    createdAt: "2024-07-18T17:44:51.000Z",
    updatedAt: "2024-07-24T20:24:09.000Z",
  },
  {
    id: 5826,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 16,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "White",
    productDescription: "Hat",
    productFabric: ".",
    productSizes:
      '[{"size":"OS","quantity":"1","price":0,"cost":0,"id":"2aad6a31-b74c-430c-9420-2c78f055f62d","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%201.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=a2d8dd1e76192cd8b7538ae723d60478418873c4215cef79cc901df6666a3f81&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 1,
    totalPrice: "0",
    notes: "<p>Dad</p><p>EST. 2006</p><p>Royal Blue Thread</p>",
    isReady: true,
    createdAt: "2024-07-18T21:44:27.000Z",
    updatedAt: "2024-07-24T20:24:10.000Z",
  },
  {
    id: 5827,
    companyId: 1,
    WOId: 1718,
    userId: 3,
    sortId: 16,
    productType: "Embroidery",
    productStyle: "BX001",
    productColor: "White",
    productDescription: "Last Entry Hat",
    productFabric: ".",
    productSizes:
      '[{"size":"OS","quantity":"1","price":0,"cost":0,"id":"2aad6a31-b74c-430c-9420-2c78f055f62d","sortId":1}]',
    productImage:
      "https://kms.nyc3.digitaloceanspaces.com/kms/wo/undefined/products/BLUE%20WHALE%20HAT%202024%205.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240730%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240730T105032Z&X-Amz-Expires=900&X-Amz-Signature=8ef52547c48630f1d2df5a1d5429d3c470486660237302af9e7607f51149140e&X-Amz-SignedHeaders=host&x-id=GetObject",
    isCommission: null,
    isCommissionType: null,
    isCommissionValue: null,
    isCommissionTotal: null,
    hasName: false,
    hasNumbers: false,
    totalQuantity: 1,
    totalPrice: "0",
    notes: "<p>Last</p><p>EST. 2006</p><p>Entry</p>",
    isReady: true,
    createdAt: "2024-07-18T21:44:27.000Z",
    updatedAt: "2024-07-24T20:24:10.000Z",
  },
];

const selectedQuote = {
  id: 3,
  companyId: 3,
  customerId: 103,
  locationId: 14,
  taxRateId: null,
  bidId: 4,
  jobName: "dfd",
  contactFirstName: "tariq",
  contactLastName: null,
  contactPhone: "14018435182",
  contactEmail: "tariq.dev43@gmail.com",
  contactDepartment: null,
  status: "pending",
  bidDate: null,
  customerBidId: null,
  deliveryMethod: null,
  dueDate: null,
  estimatedValue: "265.25",
  openingDate: "2024-08-28T00:00:00.000Z",
  result: "won",
  amendmentFollowUpDate: "2024-08-23T00:00:00.000Z",
  amendmentNotificationLocation: null,
  bidNotes: null,
  createdAt: "2024-08-16T11:25:53.000Z",
  updatedAt: "2024-08-26T09:42:10.000Z",
  location: {
    name: "Nova",
    logo: "nova-logo.jpg",
    pdfLogo: "kms/companyLogos/3/locationLogo/pdfLogo/Nova-Retail.png",
  },
  taxrate: null,
  customer: {
    id: 103,
    companyId: 3,
    customerId: 4,
    userId: 5,
    isActive: null,
    customerName: "test t 3",
    accountPayableContact: null,
    accountPayablePhone: "14018435182",
    accountPayableEmail: "tariq.dev43@gmail.com",
    addressCompany: "3",
    addressContact: "3",
    addressLine1: "3",
    addressLine2: "3",
    addressCity: "sialkot",
    addressState: "3",
    addressZipCode: "51310",
    addressCountry: "Pakistan",
    locationId: 14,
    salesManager: null,
    salesPersonId: null,
    isTaxExamption: null,
    taxNumber: null,
    taxAccountId: null,
    w9File: "",
    examptionFile: "",
    externalCustomerId: null,
    poRequired: null,
    paymentTermsId: null,
    creditLimit: null,
    creditHold: null,
    customerLogo: "",
    website: null,
    discountId: null,
    customerReference: null,
    customerType: null,
    customField1: null,
    customField2: null,
    customField3: null,
    isCommission: null,
    commissionType: null,
    commissionValue: null,
    createdAt: "2023-06-05T05:31:34.000Z",
    updatedAt: "2024-05-28T11:43:07.000Z",
    customerinfos: [
      {
        id: 63,
        companyId: 3,
        customerId: 103,
        userId: 5,
        sortId: 1,
        type: "contact",
        email: "tariqtahir43@gmail.com",
        phone: "+9230407554654",
        data: '{"firstName":"tariq 4","lastName":"tahir","department":"Software Developer","phone":"+9230407554654","mobilePhone":"+9230407554654","smsNotification":true,"email":"tariqtahir43@gmail.com"}',
        createdAt: "2023-06-20T11:46:17.000Z",
        updatedAt: "2024-04-23T07:44:21.000Z",
      },
      {
        id: 64,
        companyId: 3,
        customerId: 103,
        userId: 5,
        sortId: 1,
        type: "address",
        email: null,
        phone: null,
        data: '{"description":"excel description","addressCompanyName":"Excel Company","attentionTo":"Tariq","address1":"Excel address 1","address2":"Excel Address 2","city":"Sialkot","state":"PB","zipcode":51310,"country":"Pakistan"}',
        createdAt: "2023-06-20T11:46:17.000Z",
        updatedAt: "2023-06-20T11:46:17.000Z",
      },
    ],
  },
  bidPlanner: null,
  quoteProducts: [
    {
      id: 9,
      companyId: 3,
      quoteId: 3,
      sortId: 1,
      styleNo: "S-1036 1",
      description: "This is product Description",
      color: "asdf",
      fabric: "Interlock",
      quantity: "2",
      price: "22.50",
      createdAt: "2024-08-15T17:26:21.000Z",
      updatedAt: "2024-08-19T10:22:43.000Z",
    },
    {
      id: 11,
      companyId: 3,
      quoteId: 3,
      sortId: 2,
      styleNo: null,
      description: null,
      color: null,
      fabric: null,
      quantity: "45",
      price: "2.00",
      createdAt: "2024-08-19T10:28:00.000Z",
      updatedAt: "2024-08-19T10:28:00.000Z",
    },
    {
      id: 12,
      companyId: 3,
      quoteId: 3,
      sortId: 3,
      styleNo: null,
      description: null,
      color: null,
      fabric: null,
      quantity: "635",
      price: "4.25",
      createdAt: "2024-08-19T10:28:09.000Z",
      updatedAt: "2024-08-19T10:28:09.000Z",
    },
  ],
};
function WorkOrdersPdf() {
  const [locationLogo, setLocationLogo] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [groupedProductList, setGroupedProductList] = useState(null);

  const selectedWO = {
    id: 112,
    companyId: 1,
    userId: 3,
    customerId: 209,
    customerName: "Blue Whale Pool Pro",
    jobName: "Apparel 2024",
    WOStatus: "processing",
    createdBy: "Nabeel Javaid",
    locationId: null,
    WODate: "2024-07-06T00:00:00.000Z",
    WOShipDate: "2024-07-06T00:00:00.000Z",
    salesPersonId: null,
    taxExamption: null,
    taxRateId: null,
    paymentTermsId: 1,
    isCommission: null,
    shipmentMethod: null,
    subTotal: null,
    shippingTotal: null,
    grandTotal: null,
    billingAddress:
      '{"description":"b_address_description","addressCompany":"b_address_company","addressLine1":"Rangpura","addressLine2":"Chori Gali","city":"sialkot","state":"PB","zip":"51310","country":"Pakistan"}',
    shippingAddress:
      '{"description":"excel description","addressCompany":"Excel Company","addressLine1":"Excel address 1","addressLine2":"Excel Address 2","city":"Sialkot","state":"PB","zip":51310,"country":"Pakistan"}',
    contactData: {
      email: "bluewhalepoolpro@gmail.com",
      phone: "770-893-9017",
      lastName: "Vesali",
      firstName: "Morgan",
      department: "",
    },
    notes: "",
    createdAt: "2024-07-06T17:16:32.000Z",
    updatedAt: "2024-07-19T20:59:51.000Z",
    location: null,
    salesPerson: null,
    paymentTerm: {
      name: "Due Upon Receipt",
    },
    taxrate: null,
    customer: {
      id: 209,
      companyId: 1,
      customerId: 193,
      userId: 3,
      isActive: null,
      customerName: "Blue Whale Pool Pro",
      accountPayableContact: null,
      accountPayablePhone: null,
      accountPayableEmail: null,
      addressCompany: null,
      addressContact: null,
      addressLine1: null,
      addressLine2: null,
      addressCity: null,
      addressState: null,
      addressZipCode: null,
      addressCountry: null,
      locationId: null,
      salesManager: null,
      salesPersonId: null,
      isTaxExamption: null,
      taxNumber: null,
      taxAccountId: null,
      w9File: null,
      examptionFile: null,
      externalCustomerId: null,
      poRequired: null,
      paymentTermsId: 1,
      creditLimit: null,
      creditHold: null,
      customerLogo: null,
      website: null,
      discountId: null,
      customerReference: null,
      customerType: null,
      customField1: null,
      customField2: null,
      customField3: null,
      isCommission: null,
      commissionType: null,
      commissionValue: null,
      createdAt: "2024-07-06T17:15:23.000Z",
      updatedAt: "2024-07-06T17:16:20.000Z",
      customerinfos: [
        {
          id: 250,
          companyId: 1,
          customerId: 209,
          userId: 3,
          sortId: 1,
          type: "contact",
          data: '{"firstName":"Morgan","lastName":"Vesali","department":"","phone":"770-893-9017","mobilePhone":"","smsNotification":false,"email":"bluewhalepoolpro@gmail.com"}',
          createdAt: "2024-07-06T17:16:04.000Z",
          updatedAt: "2024-07-06T17:16:04.000Z",
        },
      ],
    },
    shippingMethod: null,
    WOProducts: [
      {
        id: 130,
        companyId: 3,
        WOId: 112,
        userId: 5,
        sortId: 1,
        productType: "Sublimation",
        productStyle: "Sub-1575",
        productColor: "Yellow",
        productDescription: "Plain Shirt with sublimation design in chest",
        productFabric: "Interlock",
        productSizes:
          '[{"size":"AM","quantity":"126","price":"2.65","cost":333.9,"id":"afb33279-1114-4be8-9d19-6f481ff60b5e","sortId":2},{"size":"SM","quantity":"50","price":"4.5","cost":225,"id":"d81effd7-9002-4a5f-a957-6fbd217ac3c2","sortId":1}]',
        productImage:
          "https://kms.nyc3.digitaloceanspaces.com/kms/wo/112/products/146796478-2155-pic-2_2024-08-13T07-12-38.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240827T072356Z&X-Amz-Expires=900&X-Amz-Signature=6b640308a0104ab7fc0ac6c26969cf8d4894f0afbdc43283262e415e355e0daf&X-Amz-SignedHeaders=host&x-id=GetObject",
        isCommission: null,
        isCommissionType: null,
        isCommissionValue: null,
        isCommissionTotal: null,
        hasName: null,
        hasNumbers: null,
        teamName: "Team 1",
        totalQuantity: 176,
        totalPrice: 559,
        notes: "",
        isReady: false,
        createdAt: "2024-07-02T05:36:40.000Z",
        updatedAt: "2024-08-13T08:17:18.000Z",
      },
      {
        id: 131,
        companyId: 3,
        WOId: 112,
        userId: 5,
        sortId: 2,
        productType: "Screen Printing",
        productStyle: "SCR-6658",
        productColor: "Brown",
        productDescription: "Screen Printed logo on chest",
        productFabric: "Lycra",
        productSizes:
          '[{"size":"SM","quantity":"12","price":"3.45","cost":41.400000000000006,"id":"f11b648c-1570-49cc-bc15-6c9d312646a4","sortId":1},{"size":"AM","quantity":"24","price":"4.65","cost":111.60000000000001,"id":"f50cb5cd-a0ab-494f-ab3f-8c78219ed6c0","sortId":2}]',
        productImage:
          "https://kms.nyc3.digitaloceanspaces.com/kms/wo/112/products/20076294-1945-pic-3.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240827T072356Z&X-Amz-Expires=900&X-Amz-Signature=a306f5e5028a5ee2bd9b12dff5fcc81ceded7c7822515b51007bf8b96f1d169c&X-Amz-SignedHeaders=host&x-id=GetObject",
        isCommission: null,
        isCommissionType: null,
        isCommissionValue: null,
        isCommissionTotal: null,
        hasName: null,
        hasNumbers: null,
        teamName: "Team 2",
        totalQuantity: 36,
        totalPrice: 153,
        notes: "",
        isReady: false,
        createdAt: "2024-07-02T05:40:23.000Z",
        updatedAt: "2024-08-13T07:12:54.000Z",
      },
      {
        id: 132,
        companyId: 3,
        WOId: 112,
        userId: 5,
        sortId: 3,
        productType: "Digital Print",
        productStyle: "JSK-21321",
        productColor: "YELLOW",
        productDescription: "JERSEY",
        productFabric: "asdas",
        productSizes:
          '[{"size":"S","quantity":"3","price":"20","cost":60,"id":"91a1647c-3e1a-47f9-9a97-5d4821626492","sortId":2},{"size":"XS","quantity":"7","price":"21.5","cost":150.5,"id":"35fbf4c8-033a-4eee-98bd-ed308fa69568","sortId":1},{"size":"M","quantity":"8","price":"6.50","cost":52,"id":"5c32adf5-ec0a-4b47-905d-30b136ba0a1a","sortId":3},{"size":"l","quantity":"8","price":"6.50","cost":52,"id":"e9757ae9-f183-4cb9-948e-e43de00eb592","sortId":4},{"size":"XL","quantity":"8","price":"22.50","cost":180,"id":"68b3dc7f-bc56-4163-ab3d-bbec6e3b855d","sortId":5}]',
        productImage:
          "https://kms.nyc3.digitaloceanspaces.com/kms/wo/112/products/146796478-2155-pic-2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240827T072356Z&X-Amz-Expires=900&X-Amz-Signature=8bf4bd30424525889633a4ee6ef2e7a28811ea9496fdaccc4a6a55aba261ec8d&X-Amz-SignedHeaders=host&x-id=GetObject",
        isCommission: null,
        isCommissionType: null,
        isCommissionValue: null,
        isCommissionTotal: null,
        hasName: null,
        hasNumbers: null,
        teamName: "Team 2",
        totalQuantity: 34,
        totalPrice: 0,
        notes: "<p>aasdasd</p>",
        isReady: false,
        createdAt: "2024-07-02T06:24:43.000Z",
        updatedAt: "2024-08-13T07:13:00.000Z",
      },
      {
        id: 133,
        companyId: 3,
        WOId: 112,
        userId: 14,
        sortId: 4,
        productType: "Embroidery",
        productStyle: "Sh-001",
        productColor: "RED",
        productDescription: "SKD-123",
        productFabric: "INTERLOCK-287",
        productSizes:
          '[{"size":"s","quantity":"18","price":"4.2","cost":75.60000000000001,"id":"5a5398dc-1918-473e-9791-6d2da7371a60","sortId":2},{"size":"xs","quantity":"8","price":"4.2","cost":33.6,"id":"a9869dbd-cf3d-4b82-89ac-89c05ae4f025","sortId":1},{"size":"m","quantity":"6","price":"4.5","cost":27,"id":"1b89a15b-3c19-4d43-8aa6-d942ec759ee3","sortId":3},{"size":"l","quantity":"12","price":"4.5","cost":54,"id":"8566ab17-842a-4f42-89a9-924b28e9707f","sortId":4},{"size":"xl","quantity":"9","price":"4.8","cost":43.199999999999996,"id":"7425948f-ff30-4dff-957a-d430a1806066","sortId":5}]',
        productImage:
          "https://kms.nyc3.digitaloceanspaces.com/kms/wo/112/products/25719794-1963-pic-2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240827T072356Z&X-Amz-Expires=900&X-Amz-Signature=e11d4276a50b7fc01d1c6fed96622d57509ed6ec536071fdf811a9ea300e497c&X-Amz-SignedHeaders=host&x-id=GetObject",
        isCommission: null,
        isCommissionType: null,
        isCommissionValue: null,
        isCommissionTotal: null,
        hasName: null,
        hasNumbers: null,
        teamName: "Team 1",
        totalQuantity: 53,
        totalPrice: 0,
        notes: "",
        isReady: true,
        createdAt: "2024-07-02T09:06:52.000Z",
        updatedAt: "2024-08-13T07:13:13.000Z",
      },
      {
        id: 140,
        companyId: 3,
        WOId: 112,
        userId: 5,
        sortId: 5,
        productType: "Blank",
        productStyle: "Blk-001",
        productColor: "Orange",
        productDescription: "Blank-Product",
        productFabric: "f",
        productSizes:
          '[{"size":"xs","quantity":"6","price":"3.2","cost":19.200000000000003,"id":"8741ae95-2b80-465e-9949-ba9084faebd3","sortId":1},{"size":"s","quantity":"7","price":"3.2","cost":22.400000000000002,"id":"be5b7b31-e0de-4c29-8afa-6d406b78228d","sortId":2},{"size":"m","quantity":"8","price":"3.8","cost":30.4,"id":"12ed27ad-89c0-439c-8861-c519715386dc","sortId":3},{"size":"xl","quantity":"15","price":"4.5","cost":67.5,"id":"b1947cec-3025-4bc9-b02c-8ef10e4a4f63","sortId":5},{"size":"l","quantity":"12","price":"3.8","cost":45.599999999999994,"id":"f466215f-65f8-4c97-b9a2-6ddd650a4687","sortId":4}]',
        productImage:
          "https://kms.nyc3.digitaloceanspaces.com/kms/wo/112/products/39819905-1825-pic-1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00GKGBNPLH36ZMAXR3%2F20240827%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240827T072356Z&X-Amz-Expires=900&X-Amz-Signature=44555614aa4ccdc2a42bfa05c9e419db18af5f6b643b6245d2c9b063be8fd682&X-Amz-SignedHeaders=host&x-id=GetObject",
        isCommission: null,
        isCommissionType: null,
        isCommissionValue: null,
        isCommissionTotal: null,
        hasName: null,
        hasNumbers: null,
        teamName: null,
        totalQuantity: 48,
        totalPrice: 0,
        notes: "",
        isReady: false,
        createdAt: "2024-08-13T08:49:41.000Z",
        updatedAt: "2024-08-13T08:49:41.000Z",
      },
    ],
  };

  const getLocationLogo = async () => {
    const { data } = await API.post(
      "/design-request/get-image",
      { path: selectedQuote?.location?.pdfLogo },
      {
        headers: authHeader(),
      }
    );
    setLocationLogo(data);
    setShowPdf(true);
    return data;
  };

  const getGroupedProducts = (list) => {
    let productListObj = JSON.parse(list);
    let groupedProduct = productListObj.reduce((acc, product) => {
      acc[product?.productType] = [
        ...(acc[product?.productType] || []),
        product,
      ];
      return acc;
    }, {});
    return groupedProduct;
  };

  useEffect(() => {
    if (selectedQuote?.location?.pdfLogo) {
      getLocationLogo();
    } else {
      setShowPdf(true);
    }
  }, [selectedQuote]);

  useEffect(() => {
    let groupedProducts = getGroupedProducts(JSON.stringify(data));
    setGroupedProductList(groupedProducts);
  }, [data]);

  return (
    <div
      style={{
        top: 0,
        width: "calc(100vw - 250px)",
        marginLeft: 280,
      }}
    >
      <button
        id="open-custom-folder"
        data-path="2024"
        className="btn btn-success"
      >
        Open Folder
      </button>

      {/*   Grouped Product
       ********************************************* */}
      {/* {showPdf && (
        <PDFViewer style={{ height: "90vh", width: "100%" }}>
          <ProductPdf
            productList={groupedProductList}
            allProdList={data}
            selectedWorkOrder={selectedWO}
            locationLogo={locationLogo}
            showInvoicePage={false}
            isTeamView={true}
          />
        </PDFViewer>
      )} */}
      {/*   WO Pdf ALL
       ********************************************* */}
      {locationLogo && (
        <PDFViewer style={{ height: "90vh", width: "100%" }}>
          <WOPdf
            selectedWO={selectedWO}
            productsList={selectedWO?.WOProducts}
            locationLogo={locationLogo}
            showInvoicePage={false}
          />
        </PDFViewer>
      )}
      {/*   Quote Pdf
       ********************************************* */}
      {/* {locationLogo && (
        <PDFViewer style={{ height: "90vh", width: "100%" }}>
          <QuotePdf
            selectedQuote={selectedQuote}
            productsList={selectedQuote?.quoteProducts}
            locationLogo={locationLogo}
            showTotal={true}
          />
        </PDFViewer>
      )} */}

      {/*   Team View Pdf
       ********************************************* */}
      {/* {locationLogo && (
        <PDFViewer style={{ height: "90vh", width: "100%" }}>
          <TeamViewPdf
            selectedWO={selectedWO}
            productsList={selectedWO?.WOProducts}
            locationLogo={locationLogo}
            showInvoicePage={false}
          />
        </PDFViewer>
      )} */}
      {/* {data && (
        <PDFDownloadLink
          document={
            <MyDocument
              productList={JSON.stringify(data)}
              selectedWO={selectedWO}
            />
          }
          fileName="testPdf"
        >
          <button className="btn btn-primary">Download</button>
        </PDFDownloadLink>
      )} */}
    </div>
  );
}

export default WorkOrdersPdf;
