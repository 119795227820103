import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Public Views
import ForgetPassword from "./pages/ForgetPassword";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NoMatch from "./pages/NoMatch";
import ResetPassword from "./pages/ResetPassword";

// Dashboard Views
import Users from "./Dashboard/Users";
import AddUser from "./Dashboard/Users/AddUser";
import Dashboard from "./pages/Dashboard";
import PasswordManager from "./pages/PasswordManager/PasswordManager";
import ProtectedRoute from "./routing/ProtectedRoute";
// import WorkOrders from "./pages/WorkOrders";
import Companies from "./pages/Companies";
import CompaniesRegister from "./pages/Companies-register";
import CompaniesUpdate from "./pages/Companies-update";
import Customers from "./pages/Customers/Customers";
import SmsMessages from "./pages/SmsMessages";
import Tracking from "./pages/Tracking";
import WorkOrders from "./pages/WorkOrders/WorkOrders";
import Settings from "./pages/settings/Settings";

import BidPlanner from "pages/BidPlanner/BidPlanner";
import CustomerLogin from "pages/CustomerLogin";
import DesignRequests from "pages/DesignRequests/DesignRequests";
import ExcelFunctions from "pages/ExcelFunctions/ExcelFunctions";
import StatusBoard from "pages/JobBoard/JobBoard";
import MockupCatalogue from "pages/MockupCatalogue/MockupCatalogue";
import PriceCalculator from "pages/PriceCalculator/PriceCalculator";
import Register from "pages/Register";
import ArtApproval from "pages/artApproval/ArtApproval";
import HowToVideos from "pages/howToVideos/HowToVideos";
import "./App.scss";
import PortalWorkOrder from "pages/CustomerPortal/WorkOrder/PortalWorkOrder";
import WorkOrdersPdf from "pages/WorkOrdersPdf";
import DetailsPage from "pages/WorkOrders/WorkOrderDetails/detailsPage";
import Quote from "pages/Quote/Quote";
import QuoteDetailsPage from "pages/Quote/QuoteDetails/QuoteDetailsPage";
import Designs from "pages/Designs/Designs";
import DesignDetailsPage from "pages/Designs/DetailsPage/DesignDetailsPage";
import Electron from "pages/WorkOrders/Electron/electron";

function App() {
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    if (!userInfo) {
      // document.body.classList.add('authentication-bg')
    } else {
      document.body.classList.remove("authentication-bg");
    }
  }, [userInfo]);

  return (
    // <>
    //   <h1 className="flex-center h-full">Website Under Maintenance</h1>
    // </>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Home />} />
        <Route path="/brick-cloud/client-login" element={<Login />} />
        <Route path="/customer-login" element={<CustomerLogin />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/register" element={<Register />} />
        <Route path="/mockups/:token" element={<ArtApproval />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/password-manager" element={<PasswordManager />} />

          <Route path="/user-management" element={<Users />} />
          <Route path="/user-management/add" element={<AddUser />} />
          <Route path="/user-management/add/:id" element={<AddUser />} />

          <Route path="/user-management" element={<Users />} />
          <Route path="/user-management/add" element={<AddUser />} />
          <Route path="/user-management/add/:id" element={<AddUser />} />

          <Route path="/mockups-list" element={<DesignRequests />} />
          <Route path="/mockups-catalogue" element={<MockupCatalogue />} />
          <Route path="/designs" element={<Designs />} />
          <Route path="/designs/details/:id" element={<DesignDetailsPage />} />
          <Route path="/sms-messages" element={<SmsMessages />} />
          <Route path="/customer" element={<Customers />} />
          <Route path="/quotes/:filterBy" element={<Quote />} />
          <Route
            path="/quotes/details/:filterBy/:selectedID"
            element={<QuoteDetailsPage />}
          />
          <Route
            path="/work-orders/:filterBy"
            element={
              userInfo?.roles !== "customer" ? <WorkOrders /> : <NoMatch />
            }
          />
          <Route
            path="/work-order/details/:filterBy/:selectedID"
            element={<DetailsPage />}
          />
          <Route path="/bid-planner" element={<BidPlanner />} />
          <Route path="/pdf-test" element={<WorkOrdersPdf />} />

          <Route path="/work-orders" element={<WorkOrders />} />

          {/*   Utility Routes
           ********************************************* */}
          <Route path="/excel-functions" element={<ExcelFunctions />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/status-board" element={<StatusBoard />} />
          <Route path="/how-to-videos" element={<HowToVideos />} />
          <Route path="/price-calculator" element={<PriceCalculator />} />

          <Route path="/electron-test" element={<Electron />} />

          {/* Customer Portal Routes */}
          <Route
            path="/portal/:company/work-order"
            element={
              userInfo?.roles === "customer" ? <PortalWorkOrder /> : <NoMatch />
            }
          />

          {/* Admin Routes */}
          <Route path="/settings" element={<Settings />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/companies/register" element={<CompaniesRegister />} />
          <Route
            path="/companies/update/:companyID"
            element={<CompaniesUpdate />}
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
