import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function QuoteBid({ selectedQuote, refreshFunc, formData, setFormData }) {
  /*   ALL STATES
   ********************************************* */
  const dispatch = useDispatch();

  const [selectedBid, setSelectedBid] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   ALL FUNCTIONS
   ********************************************* */

  const handleFormData = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "dueDate") {
      setFormData({
        ...formData,
        amendmentFollowUpDate: getThreeDaysBeforeDate(value),
        [name]: value,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      return date.toISOString().slice(0, 10).replace("T", " ");
    }
    return;
  };

  function getThreeDaysBeforeDate(initialDate) {
    if (initialDate) {
      const threeDaysAgo = new Date(initialDate);
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
      return threeDaysAgo;
    }
  }

  const updateBid = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await API.patch(
        "/bids",

        {
          data: {
            id: selectedBid?.id,
            ...formData,
          },
        },
        { headers: authHeader() }
      );
      if (data.type === "success") {
        await refreshFunc({ id: selectedBid.id });

        setIsLoading(false);
        return;
      } else {
        console.log(data);
        setIsLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  /*   All Use Effect
   ********************************************* */
  useEffect(() => {
    if (selectedBid?.customerId) {
      setFormData({
        ...selectedBid,
        amendmentFollowUpDate: getThreeDaysBeforeDate(selectedBid?.dueDate),
      });
    }
  }, [selectedBid]);

  useEffect(() => {
    setSelectedBid(selectedQuote?.bidPlanner || {});
  }, [selectedQuote]);

  useEffect(() => {
    setFormData({
      bidDate: selectedQuote?.bidDate,
      customerBidId: selectedQuote?.customerBidId,
      deliveryMethod: selectedQuote?.deliveryMethod,
      dueDate: selectedQuote?.dueDate,
      estimatedValue: selectedQuote?.estimatedValue,
      openingDate: selectedQuote?.openingDate,
      result: selectedQuote?.result,
      amendmentFollowUpDate: selectedQuote?.amendmentFollowUpDate,
      amendmentNotificationLocation:
        selectedQuote?.amendmentNotificationLocation,
      bidNotes: selectedQuote?.bidNotes,
    });
  }, [selectedQuote]);

  return (
    <div className="d-flex flex-grow-1 ">
      <div className="d-flex flex-grow-1 flex-column">
        {isLoading && <Spinner />}
        <form
          onSubmit={updateBid}
          style={{ height: "80vh" }}
          className="tab-pane show active mt-2 overflow-auto scrollbar-hide"
        >
          {/* Bids Dates */}
          <div>
            <div className="d-flex justify-content-between gap-2 mb-2 bg-white custom-shadow px-2 py-3 custom-border-radius ">
              <div className="w-25">
                <label className="form-label fw-bold mb-1 text-uppercase">
                  Bid Date
                </label>
                <input
                  required
                  className="form-control"
                  type="date"
                  placeholder="Enter Bid Date"
                  value={getDate(formData?.bidDate)}
                  name="bidDate"
                  onChange={handleFormData}
                />
              </div>
              <div className="flex-grow-1">
                <label className="form-label fw-bold text-uppercase">
                  Customer Bid ID
                </label>
                <input
                  required
                  className="form-control "
                  type="text"
                  placeholder="Enter Custom Bid ID"
                  value={formData?.customerBidId}
                  name="customerBidId"
                  onChange={handleFormData}
                />
              </div>
              <div className="flex-grow-1">
                <label className="form-label fw-bold mb-1 text-uppercase">
                  Delivery Method
                </label>
                <input
                  required
                  className="form-control"
                  type="text"
                  placeholder="Enter Delivery Method"
                  value={formData?.deliveryMethod}
                  name="deliveryMethod"
                  onChange={handleFormData}
                />
              </div>
              <div className="w-25">
                <label className="form-label fw-bold mb-1 text-uppercase">
                  Closing Date
                </label>
                <input
                  required
                  className="form-control"
                  type="date"
                  placeholder="Enter Due Date"
                  value={getDate(formData?.dueDate)}
                  name="dueDate"
                  onChange={handleFormData}
                />
              </div>
            </div>
          </div>

          {/* Vid Value/Result */}
          <div className="mb-2 d-flex justify-content-between gap-2 bg-white custom-shadow px-2 py-3 custom-border-radius ">
            <div className="flex-grow-1">
              <label className="form-label fw-bold mb-0 text-uppercase">
                Estimated Value
              </label>
              <div className="d-flex gap-2">
                <div className="ms-1 mt-2">$</div>
                <input
                  required
                  className="form-control mb-2"
                  type="number"
                  step={0.01}
                  placeholder="Enter Estimated Value"
                  value={parseFloat(formData?.estimatedValue)}
                  name="estimatedValue"
                  onChange={handleFormData}
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <label className="form-label fw-bold mb-0 text-uppercase">
                Opening Date
              </label>
              <input
                required
                className="form-control  mb-2"
                type="date"
                placeholder="Enter Open Date"
                name="openingDate"
                value={getDate(formData?.openingDate)}
                onChange={handleFormData}
              />
            </div>
            <div className="flex-grow-1">
              <label className="form-label  fw-bold mb-0 text-uppercase">
                Result
              </label>
              <select
                value={formData?.result}
                onChange={handleFormData}
                name="result"
                className="form-select"
              >
                <option value={"none"}>None</option>
                <option value={"won"}>Won</option>
                <option value={"partially_Won"}>Partially Won</option>
                <option value={"lost"}>Lost</option>
                <option value={"abandoned_By_Customer"}>
                  Abandoned By Customer
                </option>
                <option value={"abandonedByStaff"}>Abandoned By Staff</option>
              </select>
            </div>
          </div>

          {/* Amendments /notes */}
          <div className="mb-3 bg-white custom-shadow px-2 py-3 custom-border-radius ">
            <div className="d-flex gap-2">
              <div className="mb-2 flex-grow-1">
                <label className="form-label fw-bold mb-1 text-uppercase">
                  AMENDMENT FOLLOW-UP DATE
                </label>
                <input
                  required
                  className="form-control"
                  type="date"
                  value={getDate(formData?.amendmentFollowUpDate)}
                  name="amendmentFollowUpDate"
                  onChange={handleFormData}
                />
              </div>
            </div>
            <div>
              <label className="form-label fw-bold mb-1 text-uppercase">
                Amendment Notification Location
              </label>
              <input
                required
                className="form-control"
                type="text"
                placeholder="Enter Amendment Notification Location"
                value={formData?.amendmentNotificationLocation}
                name="amendmentNotificationLocation"
                onChange={handleFormData}
              />
            </div>
          </div>
          <div className="mb-3 bg-white custom-shadow px-2 py-3 custom-border-radius ">
            <div>
              <ReactQuill
                modules={modules}
                theme="snow"
                value={formData?.notes}
                onChange={(val) => setFormData({ ...formData, notes: val })}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuoteBid;
