import Table from "components/DataTable/Table";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { socket } from "../../socket";
import AddDesignRequest from "./AddDesignRequest";
import DesignRequestLayout from "./DesignRequest/DesignRequestLayout";
import ImageWIthSignedUrl from "./DesignRequest/ImageWIthSignedUrl";

export default function DesignRequests() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.userInfo);
  /*   ALL STATES
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);
  const [designRequests, setDesignRequests] = useState(null);

  const [filterData, setFilterData] = useState(null);
  const [locationsList, setLocationsList] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState(false);

  const [customerData, setCustomerData] = useState(null);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };

  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  // get Data
  const getDesignRequests = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/design-request/listfiles", {
        headers: authHeader(),
      });

      let updatedData = data.map((item) => {
        let parsedData = { ...item, products: JSON.parse(item.products) };
        return parsedData;
      });

      setDesignRequests(updatedData);
      loadingOff();
      return updatedData;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  // Delete Quotations
  const deleteDesignRequests = async (id) => {
    try {
      loadingOn();
      const { data } = await API.post("/design-request/delete-file", id, {
        headers: authHeader(),
      });
      getDesignRequests();
      setShowDeleteModal(false);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomerData(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const getCustomerNameFromId = (customerData, id) => {
    return customerData?.find((item) => item.id === id)?.customerName;
  };

  const getAllLocation = async () => {
    try {
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocationsList(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const filterDataByLocation = (e) => {
    let value = e.target.value;
    if (value === "" || null) {
      return setFilterData(null);
    }

    let filteredData = designRequests?.filter(
      (item) => item?.customer?.locationId === parseInt(value)
    );
    setFilterData(filteredData);
  };

  // Adds Button Function
  useEffect(() => {
    if (selectedDesign) {
      setSelectedDesign((prev) => {
        return designRequests.find((item) => item?.id === selectedDesign?.id);
      });
    }
  }, [designRequests, selectedDesign]);

  useEffect(() => {
    if (!showDetailsPage) {
      getDesignRequests();
      getAllLocation();
      getCustomersData();
    }
  }, [showDetailsPage]);

  useEffect(() => {
    if (selectedDesign?.id && showDetailsPage) {
      socket.on(`mockups-app`, (data) => {
        // if (parseInt(data?.id) === selectedDesign?.id) {
        //   getDesignRequests();
        // }
        getDesignRequests();
      });
    }
  }, [socket, selectedDesign]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      <>
        <button
          onClick={() => setShowAddModal(true)}
          type="button"
          className="btn btn-primary  ms-1"
        >
          ADD MOCKUP
        </button>
      </>
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* Search Filter by FilterBy */}
      <div className="d-flex gap-2 align-items-center">
        <label className="text-nowrap"> Filter By Location </label>
        <select onChange={filterDataByLocation} className="form-control">
          <option value={""}>All</option>
          {locationsList &&
            locationsList?.map((item) => (
              <option key={item?.id} value={item?.id}>
                {item?.locationName}
              </option>
            ))}
        </select>
      </div>

      {/* Search Filter by FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-stdesign"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <a
          onClick={() => setFilterBy(null)}
          className="dropdown-item"
          role={"button"}
        >
          Filter by All
        </a>
        <a
          onClick={() => setFilterBy("title")}
          className="dropdown-item"
          role={"button"}
        >
          Title
        </a>
      </div>
    </div>
  );

  const table_body = (item, index) => (
    <tr
      className="d-flex align-items-center shadow-sm  custom-border-radius-sm bg-white my-2"
      key={item.id}
    >
      {/* <td
        className="border-0 flex-shrink-0 flex-center"
        style={{ width: 130, height: 100 }}
      >
        {item?.products && item?.products?.length ? (
          <ImageWIthSignedUrl
            path={item?.products[0]?.images[0].img}
            alt={"design image"}
            imgClasses="custom-border-radius w-100 h-100"
          />
        ) : (
          <h3 className="fw-bold text-center">No Product</h3>
        )}
      </td> */}
      <td className="flex-grow-1 bg-white border-0 d-flex flex-column  ms-2">
        <h3 className="mt-0 mb-1">{item?.title}</h3>
        <div className="h5 my-0 mt-0">
          {getCustomerNameFromId(customerData, item.customerId)} -{" "}
          {item?.customer?.location?.locationName}
        </div>
      </td>

      <td className="ms-5 bg-white table-action border-0 align-baseline text-end d-flex align-items-center gap-3">
        <div className=" bg-white flex-center gap-1 w-25 border-0 align-middle">
          <span className="badge bg-danger float-end">
            {!item?.isRead && `new`}
          </span>
          <i className="mdi mdi-chat h4"></i>
          {item?.products &&
            item?.products?.length &&
            item?.products[0]?.comments?.length}
        </div>
        <div className=" bg-white border-0 ">
          <div
            className={`text-white text-center  mx-auto p-1 custom-border-radius-sm ${
              item?.approved ? "bg-success" : "bg-danger"
            }`}
          >
            {item?.approved ? "Approved" : "Pending"}
          </div>
        </div>
        <div className="d-flex gap-1">
          <button
            onClick={() => {
              setShowDetailsPage(true);
              setSelectedDesign(item);
            }}
            className="btn btn-sm btn-primary"
          >
            Details
          </button>
          <div
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID({
                id: item.id,
                file: `kms/design-requests/${user?.companyId}/${
                  item?.customerId
                }/${item?.title.replaceAll(" ", "_")}/`,
              });
            }}
            className="action-icon"
          >
            <i className="mdi mdi-delete align-middle h3"></i>
          </div>
        </div>
      </td>
    </tr>
  );
  if (!designRequests || !customerData) return <Spinner />;
  return (
    <div
      className=""
      style={{
        marginLeft: 280,
        height: "90vh",
        paddingRight: 30,
      }}
    >
      {loading && <Spinner />}
      <Toast />
      {!showDetailsPage && (
        <div className="d-flex pt-3 justify-content-between align-items-center">
          <h3 className="px-2">MOCKUPS</h3>
        </div>
      )}
      {/*   Table
       ********************************************* */}
      {!showDetailsPage && !selectedDesign ? (
        <div
          className="px-2 flex-grow-1 d-flex mt-2"
          style={{ minHeight: "50vh", borderRadius: 15 }}
        >
          <div className="horizontal-scrollable d-flex flex-grow-1">
            <Table
              filterBy={filterBy}
              buttons={buttons}
              btn1={btn1}
              table_body={table_body}
              table_data={filterData ? filterData : designRequests}
            />
          </div>
        </div>
      ) : (
        <div>
          <DesignRequestLayout
            refreshFunc={getDesignRequests}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            designRequests={designRequests}
            setDesignRequests={setDesignRequests}
            setShowDetailsPage={setShowDetailsPage}
          />
        </div>
      )}
      {/*   ADD Modal
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <AddDesignRequest
            customerData={customerData}
            setShowAddModal={setShowAddModal}
            getDesignRequests={getDesignRequests}
          />
        </Modal.Body>
      </Modal>
      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className="bg-danger">
          <div className="">
            <div className="text-center text-white">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-light my-2 mx-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteDesignRequests(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
