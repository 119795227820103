exports.GetNameWithTimeStamp = (name) => {
  // Get current timestamp
  let timeStamp = new Date()
    .toISOString()
    .replace(/:/g, "-")
    .replace(/\..+/, "");

  // Split name into filename and extension
  const parts = name.split(".");
  const filename = parts.shift();
  const extension = parts.join(".");

  // Return Name with TimeStamp
  return `${filename}_${timeStamp}.${extension}`;
};

// Getting 12 Hours Time from SQL timestamp
exports.convertSqlTimestampToDate = (sqlTime) => {
  const timestamp = sqlTime;
  const date = new Date(timestamp);
  const time = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return time;
};
