import Spinner from "components/Spinner";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import * as markerjs2 from "markerjs2";
import { useRef } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import API from "services/axios";
import authHeader from "services/auth-header";

export default function DesignImg({
  selectedImg,
  selectedProduct,
  showMarker,
  selectedMarkerState,
  setSelectedMarkerState,
  updateSelectedProduct,
  selectedComment,
  setSelectedComment,
}) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const imgRef = useRef();
  const [imgSrc, setImgSrc] = useState(null);
  const [formData, setFormData] = useState({ comment: "" });
  const [markerState, setMarkerState] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);

  const [markerArea, setMarkerArea] = useState(false);
  // let markerArea;

  /*   All Functions
   ********************************************* */
  function showMarkerArea(selectedMarkerState) {
    if (imgRef.current !== null) {
      let markerArea = new markerjs2.MarkerArea(imgRef.current);
      setMarkerArea(markerArea);

      // attach an event handler to assign annotated image back to our image element
      markerArea.addEventListener("render", (event) => {
        if (imgRef.current) {
          // imgRef.current.src = event.dataUrl;
          const maState = event.state;
          setMarkerState(maState);
          setShowAddCommentModal(true);
        }
      });

      markerArea.settings.defaultFillColor = "transparent";
      markerArea.settings.defaultColor = "yellow";
      markerArea.availableMarkerTypes = [
        "FrameMarker",
        "ArrowMarker",
        "FreehandMarker",
        "EllipseMarker",
        "TextMarker",
      ];

      markerArea.addEventListener("close", (e) => {
        setSelectedMarkerState(null);
        setSelectedComment(null);
        markerArea.clear();
      });

      markerArea.show();
      if (selectedMarkerState) {
        markerArea.restoreState(selectedMarkerState);
      }
    }
  }

  const urlToBase64 = async (url) => {
    // Fetch the image as a blob
    const response = await fetch(url);
    const blob = await response.blob();
    // Create a file reader object
    const reader = new FileReader();
    // Return a promise that resolves with the base64 string
    const base64Url = await new Promise((resolve, reject) => {
      // Set the onloadend event handler
      reader.onloadend = async () => {
        // Get the base64 string from the result
        const base64 = reader.result;
        // Resolve the promise with the base64 string
        resolve(base64);
      };
      // Set the onerror event handler
      reader.onerror = reject;
      // Read the blob as a data URL
      reader.readAsDataURL(blob);
    });
    // console.log(base64Url)

    return base64Url;
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const addNewCommentWithMarker = async () => {
    let rawDate = new Date();
    var ampm = rawDate.getHours() >= 12 ? "pm" : "am";
    let newDate = `${
      rawDate.getHours() > 12 ? rawDate.getHours() - 12 : rawDate.getHours()
    }:${rawDate.getMinutes()}${ampm} - ${rawDate.getDate()}-${rawDate.toLocaleDateString(
      "default",
      { month: "short" }
    )}-${rawDate.getFullYear()}`;

    let newComment = {
      id: uuid(),
      user: `${user.firstName} ${user.lastName}`,
      comment: formData?.comment,
      imageVersion: selectedImg?.version,
      markerState,
      type: "text",
      date: newDate,
    };

    let newCommentData = [...selectedProduct?.comments, newComment];
    selectedProduct["comments"] = newCommentData;
    await updateSelectedProduct(selectedProduct);
    setIsLoading(false);
    setShowAddCommentModal(false);
  };

  const updateComment = () => {
    setIsLoading(true);

    let newCommentData = selectedProduct?.comments.map((item) => {
      if (item.id === formData?.id) {
        return {
          ...item,
          markerState,
          comment: formData?.comment,
        };
      } else {
        return item;
      }
    });
    // setSelectedComments((prev) => newCommentData);
    selectedProduct["comments"] = newCommentData;
    updateSelectedProduct(selectedProduct);
    setIsLoading(false);
    setFormData({ comment: "" });
    setShowAddCommentModal(false);
  };

  /*   All UseEffects
   ********************************************* */
  // useEffect(() => {
  //   if (selectedMarkerState && markerArea) {
  //     markerArea?.restoreState(selectedMarkerState);
  //   } else {
  //     if (markerArea) {
  //       markerArea?.clear();
  //     }
  //   }
  // }, [selectedMarkerState]);

  useEffect(() => {
    if (selectedComment) {
      setFormData({
        comment: selectedComment.comment,
        isUpdate: true,
        id: selectedComment?.id,
      });
    }
  }, [selectedComment]);

  useEffect(() => {
    const getImageUrl = async () => {
      const { data } = await API.post(
        "/design-request/get-image",
        { path: selectedImg.img },
        {
          headers: authHeader(),
        }
      );
      let url = await urlToBase64(data);
      setImgSrc(url);
      return data;
    };

    getImageUrl();
  }, [selectedImg]);

  if (!selectedImg || !imgSrc) return <Spinner />;

  return (
    <div className="flex-grow-1 flex-center">
      <div className="" style={{ maxWidth: 950, height: "65vh" }}>
        <img
          // ref={imgRef}
          // onLoad={() => {
          //   showMarkerArea(selectedMarkerState);
          // }}
          // onClick={() => {
          //   showMarkerArea(selectedMarkerState);
          // }}
          className=""
          src={imgSrc}
          style={{ width: "100%", height: "100%", objectFit: "" }}
          // style={{ maxWidth: 950, maxHeight: 650 }}
          alt={"product"}
        />
      </div>

      {/*   Comment Modal
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent"
        show={showAddCommentModal}
        onHide={() => setShowAddCommentModal(false)}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <h3>{selectedComment ? "Update" : "Add"} Message</h3>
          {isLoading && <Spinner />}
          <div>
            <div className="my-3">
              <label className="form-label">
                {formData?.isUpdate ? "Update Comment" : "New comment"}
              </label>
              <input
                name="comment"
                required
                value={formData?.comment}
                onChange={handleInput}
                className={`form-control bg-light ${
                  formData.comment === "" && "border-2 border-danger"
                }`}
                placeholder="Enter comment"
              />
              {formData.comment === "" && (
                <div className="text-danger">Required</div>
              )}
            </div>
            <div className="text-end mt-3">
              <button
                type="button"
                onClick={() => {
                  setShowAddCommentModal(false);
                  setFormData({ comment: "" });
                }}
                className="btn btn-sm btn-secondary mx-1"
              >
                Close
              </button>
              {formData?.isUpdate ? (
                <button
                  type="button"
                  disabled={isLoading || formData?.comment == ""}
                  onClick={() => {
                    updateComment();
                  }}
                  className="btn btn-sm mx-1 btn-primary"
                >
                  Update Message
                </button>
              ) : (
                <button
                  type="button"
                  disabled={isLoading || formData?.comment == ""}
                  onClick={() => {
                    addNewCommentWithMarker();
                  }}
                  className="btn btn-sm btn-primary"
                >
                  Add Message
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
