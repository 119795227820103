import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { userLogin } from "../features/user/userActions";
import { getCompany } from "../features/company/companyActions";
import { setNotification } from "../features/Notification/notificationSlice";

import Toast from "../components/Toast/ToastComponent";
import { Spinner } from "react-bootstrap";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  const { register, handleSubmit } = useForm();
  const { loading, userInfo } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);

  useEffect(() => {
    document.querySelectorAll(".needs-validation").forEach((form) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });

    if (
      userInfo !== null &&
      userInfo?.companyId !== undefined &&
      company?.id === userInfo?.companyId &&
      company?.subscriptionPlan !== null
    ) {
      navigate("/dashboard");
    } else if (userInfo !== null && userInfo?.companyId === undefined) {
      navigate("/signup", {
        state: { stepId: 1 },
      });
    } else if (
      userInfo !== null &&
      userInfo?.companyId !== undefined &&
      company?.subscriptionPlan === null
    ) {
      navigate("/signup", {
        state: { stepId: 2 },
      });
    }
  }, [navigate, userInfo, company]);

  const submitForm = (data) => {
    dispatch(userLogin({ ...data, type: "user" })).then((res) => {
      if (res.type === "auth/login/fulfilled") {
        dispatch(getCompany());
      } else {
        dispatch(
          setNotification({
            message: res.payload,
            type: "error",
          })
        );
      }
    });
  };

  return (
    <>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <Toast />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="index.html">
                    <span>
                      <img
                        src={require("../assets/images/logo.png")}
                        alt="logo"
                        height="50"
                      />
                    </span>
                  </a>
                </div>

                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center pb-0 fw-bold">
                      Sign In
                    </h4>
                    <p className="text-muted mb-4">
                      Enter your email address and password to access admin
                      panel.
                    </p>
                  </div>

                  <form
                    className="needs-validation"
                    onSubmit={handleSubmit(submitForm)}
                    noValidate
                  >
                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        {...register("email")}
                        required
                      />
                      <div className="invalid-feedback">
                        Please enter valid email.
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          className="form-control"
                          type={showPass ? "text" : "password"}
                          placeholder="Enter your password"
                          {...register("password")}
                          required
                        />
                        <div
                          className="input-group-text"
                          data-password="false"
                          onClick={() => setShowPass(!showPass)}
                        >
                          <span className="password-eye"></span>
                        </div>
                      </div>
                      <Link
                        to={"/forget-password"}
                        className="text-muted float-end"
                      >
                        <small>Forgot your password?</small>
                      </Link>
                    </div>

                    <div className="mb-3 mb-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="checkbox-signin"
                          name="remember"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkbox-signin"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    {loading && (
                      <div className="text-center my-2">
                        <Spinner />
                      </div>
                    )}
                    <div className="mb-3 mb-0 text-center">
                      <button className="btn btn-primary" type="submit">
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted ">
                    {/* Don't have an account?{" "} */}
                    <Link to={"/customer-login"} className=" ">
                      <span className="font-weight-bold">
                        Click here to go to Customer portal
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              {/* <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted ">
                    Don't have an account?{" "}
                    <Link to={"/signup"} className=" ">
                      <span className="font-weight-bold">
                        Click here to sign up
                      </span>
                    </Link>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
