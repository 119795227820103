import { useEffect, useState } from "react";

import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import logo from "../../assets/images/logo.png";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import ArtApprovalLayout from "./ArtApprovalLayout";

export default function ArtApproval() {
  /*   All States
   ********************************************* */
  const { token } = useParams();

  const [user, setUser] = useState({ user: "", isSet: false });

  const [selectedDesign, setSelectedDesign] = useState(null);

  const companyLogo = useSelector(
    (state) => state?.company?.company?.companyLogo
  );

  /*   All Functions
   ********************************************* */

  const getDesignByToken = async (TOKEN) => {
    try {
      const { data } = await API.post(
        "/art-approval/get-design",
        { token: TOKEN },
        { headers: authHeader() }
      );

      let selectedData = data?.designRequestData;

      let updatedData = {
        ...selectedData,
        products: JSON.parse(selectedData.products),
      };

      setSelectedDesign((prev) => {
        return updatedData;
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (token) {
      getDesignByToken(token);
    }
  }, [token]);

  useEffect(() => {
    let username = localStorage.getItem("username");
    if (username) {
      setUser((prev) => {
        return { isSet: true, user: username };
      });
    }
  }, [localStorage]);

  if (!selectedDesign) return <Spinner />;

  return (
    <div className="">
      <Toast />
      {!user.isSet ? (
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
          {/*   Guest Name Modal
           ********************************************* */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-4 col-lg-5">
                <div className="card">
                  <div className="card-header py-2 pt-4 text-center flex-center">
                    {/* {selectedDesign?.customer?.customerLogo ? (
                          <ImgWithSignedUrl
                            path={selectedDesign?.customer?.customerLogo}
                            width={100}
                          />
                        ) : selectedDesign?.customer?.location?.logo ? (
                          <img
                            src={selectedDesign?.customer?.location?.logo}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "",
                            }}
                            alt="location logo"
                          />
                        ) : companyLogo ? (
                          <ImgWithSignedUrl path={companyLogo} height={100} />
                        ) : (
                          <img
                            src={require("../../assets/images/logo.png")}
                            alt="logo"
                            height="100"
                          />
                        )} */}
                    <div style={{ width: 150, height: 100 }} className="">
                      {selectedDesign?.customer?.customerLogo ? (
                        <ImgWithSignedUrl
                          path={selectedDesign?.customer?.customerLogo}
                          styles={{
                            height: "100%",
                            width: "100%",
                            objectFit: "",
                          }}
                        />
                      ) : selectedDesign?.customer?.location?.logo ? (
                        <img
                          src={require(`../../assets/images/${selectedDesign?.customer?.location?.logo}`)}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "",
                          }}
                          alt="location logo"
                        />
                      ) : companyLogo ? (
                        <ImgWithSignedUrl
                          styles={{
                            height: "100%",
                            width: "100%",
                            objectFit: "",
                          }}
                          path={companyLogo}
                          width={40}
                        />
                      ) : (
                        <img
                          src={logo}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "",
                          }}
                          alt="logo"
                          height="20"
                        />
                      )}
                    </div>
                  </div>
                  <hr className="mx-5" />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setUser((prev) => {
                        let userStatus = {
                          user: prev.user + " (Guest)",
                          isSet: true,
                        };
                        return userStatus;
                      });
                      localStorage.setItem("username", user.user);
                    }}
                    className="card-body p-4"
                  >
                    <div className="text-center w-75 m-auto">
                      <h3 className="text-dark-50 text-center pb-0 mb-3 fw-bold">
                        Hi Guest!
                      </h3>
                      <h4 className="text-dark-50 text-center pb-0 mb-3 fw-bold">
                        Please Enter your Name
                      </h4>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter name"
                        name="email"
                        onChange={(e) => {
                          setUser((prev) => {
                            let username = { ...prev, user: e.target.value };
                            return username;
                          });
                        }}
                        required
                      />
                    </div>

                    <div className="mb-3 mb-0 text-center">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>

                <div className="row mt-3"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ArtApprovalLayout
          selectedDesign={selectedDesign}
          getDesignByToken={getDesignByToken}
          user={user}
          setUser={setUser}
        />
      )}
    </div>
  );
}
