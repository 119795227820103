import { useEffect, useState } from "react";

import ArtQuestions from "./ArtQuestions/ArtQuestions";
import ArtApprovalDetails from "./Mockups/ArtApprovalDetails";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { useDispatch } from "react-redux";
import API from "services/axios";
import authHeader from "services/auth-header";
import { setNotification } from "features/Notification/notificationSlice";
import { socket } from "../../socket";

export default function ArtApprovalLayout({
  selectedDesign,
  setSelectedDesign,
  designRequests,
  setDesignRequests,
  setShowDetailsPage,
  getDesignByToken,
  user,
  setUser,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [activeTab, setActiveTab] = useState("mockups");
  const [loading, setLoading] = useState(false);
  const [questionsList, setQuestionsList] = useState(null);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  const getQuestionsList = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/questions",
        { mockupId: selectedDesign?.id },
        {
          headers: authHeader(),
        }
      );

      if (data.type === "success") {
        setQuestionsList(data?.data);
      }

      if (data.type === "error") {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  const getUnansweredCount = (list) => {
    const count = list?.reduce((acc, cur) => {
      acc = cur?.isAnswered ? acc : acc + 1;

      return acc;
    }, 0);

    return count;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getQuestionsList();
    socket.on("question-new", (data) => {
      console.log("question-new received: " + JSON.stringify(data));
      if (selectedDesign?.id === data.id) {
        getQuestionsList();
      }
    });
  }, [socket]);
  return (
    <div className="mt-2">
      {loading && <Spinner />}
      <Toast />
      {/* <div className="d-flex justify-content-between align-items-center">
        <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1 py-2 px-4">
          <li
            className="nav-item "
            onClick={() => {
              setActiveTab("mockups");
            }}
          >
            <button
              className={
                activeTab === "mockups"
                  ? `nav-link rounded-3 active bg-primary text-white`
                  : "nav-link rounded-3 bg-white"
              }
            >
              Mockups
            </button>
          </li>
          <li
            className="nav-item "
            onClick={() => {
              setActiveTab("questions");
            }}
          >
            <button
              className={
                activeTab === "questions"
                  ? `nav-link rounded-3 active bg-primary text-white`
                  : "nav-link rounded-3 bg-white"
              }
            >
              Questions{" "}
              <span className={`${activeTab !== "questions" && "text-danger"}`}>
                ({getUnansweredCount(questionsList)})
              </span>
            </button>
          </li>
        </ul>
      </div> */}
      <div>
        {activeTab === "mockups" && (
          <ArtApprovalDetails
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            designRequests={designRequests}
            setDesignRequests={setDesignRequests}
            getDesignByToken={getDesignByToken}
            user={user}
            setUser={setUser}
          />
        )}
        {/* {activeTab === "questions" && questionsList && (
          <ArtQuestions
            selectedDesign={selectedDesign}
            questionsList={questionsList}
            getQuestionsList={getQuestionsList}
          />
        )} */}
      </div>
    </div>
  );
}
