import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import QuickViewWOProduct from "./QuickViewWOProduct";

export default function GetWOProduct({
  selectedWO,
  refreshFunc,
  sortId,
  showModal,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showProductsModal, setShowProductsModal] = useState(false);

  /*   All Functions
   ********************************************* */
  function loadingOn() {
    setLoading(true);
  }
  function loadingOff() {
    setLoading(false);
  }

  const getWOs = async (e) => {
    e.preventDefault();
    loadingOn();
    try {
      let body = { query: searchText };
      const { data } = await API.post("/work-orders-search", body, {
        headers: authHeader(),
      });
      setSearchResults(data?.result);
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.response.data.message,
          type: "error",
        })
      );
    }
    loadingOff();
  };

  const handleRemovePriceFromSizes = (sizes) => {
    let productSizes = JSON.parse(sizes);
    if (!productSizes.length) {
      return [];
    }

    let updatedProductSizes = JSON.stringify([
      {
        ...productSizes[0],
        quantity: 0,
        price: 0,
        size: "one size",
        sortId: 1,
        cost: 0,
      },
    ]);

    return updatedProductSizes;
  };

  const addProduct = async (product) => {
    if (!product) {
      return alert("Please select a valid product");
    }
    let copyObject = null;
    let updatedProductImage = product?.productImage
      ? product?.productImage?.replace(selectedItem?.id, selectedWO?.id)
      : null;
    if (product?.productImage) {
      copyObject = {
        oldKey: product?.productImage,
        newKey: updatedProductImage,
      };
    }

    try {
      loadingOn();
      delete product.sortId;
      delete product.id;
      delete product.createdAt;
      delete product.updatedAt;
      delete product.WOId;
      product.totalPrice = "00.00";
      // product.productSizes = handleRemovePriceFromSizes(product.productSizes);

      let productsData = {
        ...product,
        WOId: selectedWO?.id,
        sortId: sortId,
        productImage: updatedProductImage,
      };

      const resp = await API.post(
        `/wo-products`,
        {
          data: productsData,
          copyObject,
        },
        { headers: authHeader() }
      );
      if (resp.status === 200) {
        await refreshFunc();
        loadingOff();
        showModal(false);
      } else {
        throw new Error(resp.message);
      }
    } catch (err) {
      console.log(err.message);
      loadingOff();
    }
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="bg-white custom-border-radius custom-shadow p-3">
      <Toast />
      {loading && <Spinner />}
      <div>
        <h3>Select Products From Previous wO</h3>
      </div>
      <form onSubmit={getWOs}>
        <label className="form-label">Search WOs</label>
        <div className="d-flex gap-2">
          <input
            value={searchText}
            required
            type="search"
            className="form-control"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search WOs"
          />
          <button type="submit" className="btn btn-sm btn-primary">
            Search
          </button>
        </div>
      </form>
      {searchResults && (
        <>
          <h4>Search Results</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="fw-bold">id</th>
                <th className="fw-bold">Job Name</th>
                <th className="fw-bold">Customer Name</th>
                <th className="fw-bold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {searchResults?.map((item) => (
                <tr key={item.id} className="">
                  <td className="py-1">{item?.id}</td>
                  <td className="py-1">{item?.jobName}</td>
                  <td className="py-1">{item?.customerName}</td>
                  <td className="py-1">
                    <button
                      onClick={() => {
                        setSelectedItem(item);
                        setShowProductsModal(true);
                      }}
                      className="btn btn-sm btn-success py-0"
                    >
                      View Products
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {/*   SelectProduct Modal
       ********************************************* */}
      <Modal
        size="xl"
        show={showProductsModal}
        onHide={() => setShowProductsModal(false)}
        contentClassName="bg-transparent"
      >
        <div className="bg-white custom-border-radius custom-shadow p-3">
          <QuickViewWOProduct
            selectedWorkOrder={selectedItem}
            selectProduct={addProduct}
            setShowUpdateModal={setShowProductsModal}
          />
        </div>
        {/* <div className="bg-white custom-border-radius custom-shadow p-3">
          {selectedWOProducts && (
            <>
              <h4>Product List</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="fw-bold">id</th>
                    <th className="fw-bold">Product Type</th>
                    <th className="fw-bold">Product Style</th>
                    <th className="fw-bold">Total Price</th>
                    <th className="fw-bold">TotalQty</th>
                    <th className="fw-bold">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedWOProducts?.map((item) => (
                    <tr key={item.id} className="">
                      <td className="py-1">
                        {item?.id}
                        <ImageWIthSignedUrl
                          path={item?.productImage}
                          height={20}
                          width={20}
                          alt={"product Image" + item?.id}
                        />
                      </td>
                      <td className="py-1">{item?.productType}</td>
                      <td className="py-1">{item?.productStyle}</td>
                      <td className="py-1">{item?.totalPrice}</td>
                      <td className="py-1">{item?.totalQuantity}</td>
                      <td className="py-1">
                        <button
                          onClick={() => {
                            addProduct(item);
                          }}
                          className="btn btn-sm btn-success py-0"
                        >
                          select
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div> */}
      </Modal>
    </div>
  );
}
