import Spinner from "components/Spinner";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function DeleteModalButton({
  disableConditions,
  deleteFunc,
  deleteParams,
  btnClass,
}) {
  /*   All States
   ********************************************* */
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  function loadingOn() {
    return setLoading(true);
  }
  function loadingOff() {
    return setLoading(false);
  }

  /*   All UseEffects
   ********************************************* */
  return (
    <>
      <button
        type="button"
        className={`${btnClass ? btnClass : "btn btn-sm btn-danger"}`}
        disabled={disableConditions ? disableConditions : false}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="mdi mdi-delete "></i>
      </button>
      {/*  Delete Question
       ********************************************* */}

      <Modal
        size="sm"
        show={showDeleteModal}
        contentClassName="bg-transparent"
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Body className="bg-danger text-white custom-border-radius py-4">
          {loading && <Spinner />}

          <div className=" text-center">
            <i className="ri-close-circle-line h1"></i>
            <h4 className="mt-2">Confirm Delete!</h4>
            <p className="mt-3">Do You want to delete ?</p>
          </div>
          <div className="text-center">
            <button
              onClick={() => {
                setShowDeleteModal(false);
              }}
              disabled={loading}
              className="btn btn-sm btn-secondary"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                loadingOn();
                await deleteFunc(deleteParams);
                loadingOff();
              }}
              className="btn btn-sm btn-warning ms-1"
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
