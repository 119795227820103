import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import { socket } from '../socket';
import Spinner from 'components/Spinner';

function SmsManager() {
  const user = useSelector((state) => state.user?.userInfo);
  const scrollableDivRef = useRef(null);

  /*   ALL STATES
   ********************************************* */
  const [showSendModal, setShowSendModal] = useState(false);
  const [formError, setFormError] = useState({});
  const { register, handleSubmit } = useForm();
  const [smsBody, setSmsBody] = useState('');
  const [smsData, setSmsData] = useState(null);
  const [sendSmsBody, setSendSmsBody] = useState('');
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchChatText, setSearchChatText] = useState('');
  const [selectedDelete, setSelectedDelete] = useState(null);
  const [phone, setPhone] = useState('');

  {
    /*   ALL FUNCTIONS
     ********************************************* */
  }
  // Group Chats with same contact number
  const groupChats = (chats) => {
    const groupedObjects = chats.reduce((grouped, item) => {
      // here grouped is empty object

      // extracting the messageTo contact_number from current item
      const { messageLinks } = item;

      // if grouped object dosn't have and key with messageTo
      // then we are creating a key named as messageTo and setting value to []
      if (!grouped[messageLinks]) {
        grouped[messageLinks] = [];
      }

      // pushing the item to current messageTo contact number
      grouped[messageLinks].push(item);

      return grouped;
    }, {});

    let arr = [];
    for (let key in Object.assign(groupedObjects)) {
      const sortedArr = groupedObjects[key]?.sort(
        (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt)
      );
      arr.push({
        key,
        data: sortedArr,
        lastMessageDate: sortedArr[sortedArr?.length - 1]?.createdAt,
      });
    }

    return arr.sort((a, b) => new Date(b?.lastMessageDate) - new Date(a?.lastMessageDate));
  };

  // Get Number of unread messages from array of messages
  const countUnreadMessages = (data) => {
    let count = 0;

    data.map((message) => {
      if (message.isRead === false) {
        count++;
      }
    });
    return count;
  };

  // Get Data from db
  const getSms = async (number) => {
    try {
      const { data } = await API.get('/sms', { headers: authHeader() });
      if (data?.success) {
        const chats = groupChats(data?.success?.message);
        setSmsData(chats);
        if (number) {
          chats?.find((item) => {
            if (item?.key === number) {
              setSelectedChat(item?.data);
            }
          });
        }

        return;
      }
      if (data?.error?.message?.includes("Cannot read properties of null (reading 'apiKey')")) {
        alert('Please add your telnyx api key in settings');
        return;
      }
      if (data?.error) {
        alert(data.error.message);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  //Format Number
  const formatNumber = (number) => {
    let formatedNumber = `${number.slice(0, 2)} (${number.slice(2, 5)}) ${number.slice(
      5,
      8
    )}-${number.slice(8, 12)}`;
    return number;
  };

  // UPDATES THE MESSAGE READ STATUS
  const updateRead = async (key) => {
    try {
      const { data } = await API.post('/sms/read', { messageTo: key }, { headers: authHeader() });

      if (data.success) {
        getSms();
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    socket.on('newSms', (data) => {
      if (selectedChat) {
        getSms(selectedChat[0]?.messageLinks);
      } else {
        getSms();
      }
    });
  }, [socket]);

  // scroll to bottom
  const scrollToBottom = () => {
    const scrollableDiv = scrollableDivRef.current;
    scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
  };

  useEffect(() => {
    if (selectedChat) scrollToBottom();
  }, [selectedChat]);

  // SEND SMS FROM MODAL
  const submitForm = async (formData, e) => {
    e.preventDefault();

    try {
      const { data } = await API.post(
        '/sms/send',
        { formData: { contactNumber: `+${phone.replace(/\D/g, '')}`, smsBody } },
        { headers: authHeader() }
      );
      if (data.error) {
        if (data.error.message.includes('Missing required parameter')) {
          setSmsBody('');
          setSendSmsBody('');
          return alert('Wrong Api Phone Number');
        }
        if (data.error.message.includes('Authentication failed')) {
          setSmsBody('');
          setSendSmsBody('');
          return alert('Wrong api key of telnyx added');
        }
        setFormError({
          contactNumber: {
            message: data.error.message,
          },
        });
      }

      if (data.success) {
        setSmsBody('');
        setSendSmsBody('');
        await getSms(`+${phone.replace(/\D/g, '')}`);
        setShowSendModal(false);
        return;
      }
    } catch (err) {
      setSmsBody('');
      setSendSmsBody('');
      alert(err.message);
    }
  };

  // SEND SMS FROM SEND BUTTON
  const sendSubmit = async (formData, e) => {
    e.preventDefault();
    setFormError({});

    if (sendSmsBody === '') {
      return;
    }

    try {
      const { data } = await API.post(
        '/sms/send',
        { formData: { contactNumber: selectedChat[0]?.messageLinks, smsBody: sendSmsBody } },
        { headers: authHeader() }
      );
      if (data.error) {
        if (data.error.message.includes('Missing required parameter')) {
          setSendSmsBody('');
          return alert('Wrong api phone number');
        }
        if (data.error.message.includes('Authentication failed')) {
          setSendSmsBody('');
          return alert('Wrong api key of telnyx added');
        }
        setSendSmsBody('');
        alert(data.error.message);
        return;
      }
      if (data.success) {
        setSendSmsBody('');
        await getSms(selectedChat[0]?.messageLinks);

        setTimeout(() => {
          scrollToBottom();
        }, 300);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const onError = (data) => {
    setFormError({
      contactNumber: {
        message: data.contactNumber.message,
      },
    });
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToTime = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);

    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.toLocaleString('en-US', { day: 'numeric' });
    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return `${month} ${day} - ${time}`;
  };

  const deleteChat = async (chat) => {
    const messageLinks = chat[0].messageLinks;
    try {
      const { data } = await API.post(
        '/sms/delete',
        { messageLinks: messageLinks },
        { headers: authHeader() }
      );
      if (data?.error) {
        alert(data.error.message);
        return;
      }
      setSelectedChat(null);
      await getSms();
    } catch (err) {
      alert(err.message);
    }
  };

  // useEffect
  useEffect(() => {
    getSms();
  }, []);

  if (!smsData) return <Spinner />;

  return (
    <div className='d-flex flex-column py-5' style={{ marginLeft: 280 }}>
      <div className='d-flex'>
        {/* Import */}
        <div
          className='d-flex justify-content-center flex-grow-1'
          style={{ maxHeight: '80vh', minHeight: '80vh' }}
        >
          {/*   Chats List
           ********************************************* */}
          <div className='bg-white shadow-lg rounded p-3 me-3 d-flex'>
            <div className='rounded flex-column py-1 d-flex fle flex-grow-1' style={{ width: 300 }}>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <h4 className='mt-0 mb-0'>SMS CHATS</h4>
                {(user?.roles === 'admin' || user?.permissions?.includes('sms_send')) && (
                  <button
                    onClick={() => {
                      setSendSmsBody('');
                      setSmsBody('');
                      setPhone('');
                      setShowSendModal(true);
                    }}
                    className='btn btn-success btn-sm me-1 '
                  >
                    <small>SEND MESSAGE</small>
                  </button>
                )}
              </div>
              {/* Searh Bar */}
              <div className='app-search'>
                <form>
                  <div className='mb-2'>
                    <input
                      value={searchChatText}
                      onChange={(e) => setSearchChatText(e.target.value)}
                      type='search'
                      className='form-control'
                      placeholder='People, groups & messages...'
                    />
                    <span className='mdi mdi-magnify search-icon' />
                  </div>
                </form>
              </div>
              {/* Chat List */}
              <div className='flex-grow-1 pe-1' style={{ height: '200px', overflowY: 'scroll' }}>
                {/*   Mapping over chat list
                 ********************************************* */}
                {smsData.length != 0 &&
                  searchChatText != '' &&
                  smsData
                    .filter((i) =>
                      i?.key.toString().toLowerCase().includes(searchChatText.toLowerCase())
                    )
                    .map((item, index) => (
                      <a
                        rote='button'
                        className='text-body'
                        item={item?.key}
                        onClick={() => {
                          countUnreadMessages(item?.data) > 0 && updateRead(item?.key);
                        }}
                      >
                        <div
                          className={`d-flex align-items-start mt-1 p-2 pe-0 ${
                            selectedChat && item?.key == selectedChat?.messageLinks && 'bg-light'
                          }   rounded `}
                          onClick={() => {
                            setSelectedChat(item?.data);
                          }}
                        >
                          <img
                            src={require('../assets/images/users/acccountImage.png')}
                            className='me-2 rounded-circle bg-primary bg-opacity-50 shadow-sm'
                            height='45'
                            alt='account-image'
                          />
                          <div className='w-100 overflow-hidden '>
                            <h5 className='mt-0 mb-0 font-14 '>
                              <span className='float-end text-muted font-12 '>
                                {convertSqlTimestampToTime(item?.lastMessageDate)}
                              </span>
                              <span className=''>{formatNumber(item?.key)}</span>
                            </h5>
                            <p className='mt-1 mb-0 text-muted font-14'>
                              {countUnreadMessages(item?.data) > 0 && (
                                <span className='w-25 float-end text-end'>
                                  <span className='badge badge-danger-lighten'>
                                    {countUnreadMessages(item?.data)}
                                  </span>
                                </span>
                              )}

                              <span className='w-75'>
                                {item?.data[item?.data.length - 1]?.messageText.slice(0, 20)}...
                              </span>
                            </p>
                          </div>
                          <div
                            role={'button'}
                            className='text-end fs-3 text-danger ms-0'
                            data-bs-toggle='modal'
                            data-bs-target='#danger-alert-modal'
                            onClick={() => {
                              setSelectedDelete(item?.data);
                            }}
                          >
                            <i className='mdi mdi-delete '></i>
                          </div>
                        </div>
                      </a>
                    ))}

                {searchChatText === '' &&
                  smsData?.length != 0 &&
                  smsData?.map((item) => (
                    <a
                      rote='button'
                      className='text-body'
                      key={item?.key}
                      onClick={() => {
                        countUnreadMessages(item?.data) > 0 && updateRead(item?.key);
                      }}
                    >
                      <div
                        className={`d-flex align-items-start mt-1 p-2 pe-0 ${
                          selectedChat && item?.key == selectedChat[0]?.messageLinks && 'bg-light'
                        }   rounded `}
                        onClick={() => {
                          setSelectedChat(item?.data);
                        }}
                      >
                        <img
                          src={require('../assets/images/users/acccountImage.png')}
                          className='me-2 rounded-circle bg-primary bg-opacity-50 shadow-sm'
                          height='45'
                          alt='account-image'
                        />
                        <div className='w-100 overflow-hidden '>
                          <h5 className='mt-0 mb-0 font-14 '>
                            <span className='float-end text-muted font-12 '>
                              {convertSqlTimestampToTime(item?.lastMessageDate)}
                            </span>
                            <span className=''>{formatNumber(item?.key)}</span>
                          </h5>
                          <p className='mt-1 mb-0 text-muted font-14'>
                            {countUnreadMessages(item?.data) > 0 && (
                              <span className='w-25 float-end text-end'>
                                <span className='badge badge-danger-lighten'>
                                  {countUnreadMessages(item?.data)}
                                </span>
                              </span>
                            )}

                            <span className='w-75'>
                              {item?.data[item?.data.length - 1]?.messageText.slice(0, 20)}...
                            </span>
                          </p>
                        </div>
                        <div
                          role={'button'}
                          className='text-end fs-3 text-danger ms-0'
                          data-bs-toggle='modal'
                          data-bs-target='#danger-alert-modal'
                          onClick={() => {
                            setSelectedDelete(item?.data);
                          }}
                        >
                          <i className='mdi mdi-delete '></i>
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>
          {/*   Convsersation Section
           ********************************************* */}
          <div className=' shadow-lg rounded flex-grow-1 d-flex flex-column '>
            <ul
              className='conversation-list px-3 pt-3 flex-grow-1'
              style={{ height: 500, overflowY: 'scroll' }}
              ref={scrollableDivRef}
            >
              {selectedChat &&
                selectedChat?.map((message) => (
                  <li key={message.id} className={`clearfix  ${!message.isReceived && 'odd'}`}>
                    <div className='chat-avatar' style={{ minWidth: 50 }}>
                      <img
                        src={require('../assets/images/users/acccountImage.png')}
                        className='rounded-full bg-primary bg-opacity-50'
                        alt={message.isReceived ? message.messageFrom : message.messageTo}
                      />
                      <i className=''>{convertSqlTimestampToTime(message.createdAt)}</i>
                    </div>
                    <div className='conversation-text '>
                      {message.messageType === 'SMS' ? (
                        <div
                          className='ctext-wrap'
                          style={{
                            boxShadow: '0px 1px 2px lightgrey',
                          }}
                        >
                          <i>
                            {message.isReceived
                              ? formatNumber(message.messageFrom)
                              : formatNumber(message.messageTo)}
                          </i>

                          <p
                            onDoubleClick={() => navigator.clipboard.writeText(message.messageText)}
                          >
                            {message.messageText}
                          </p>
                        </div>
                      ) : (
                        <div
                          className='ctext-wrap'
                          style={{
                            boxShadow: '0px 0px 6px lightgrey',
                          }}
                        >
                          <i>{!message.isReceived ? message.messageFrom : message.messageTo}</i>
                          <img
                            src={message.messageData}
                            className='rounded'
                            alt={message.isReceived ? message.messageFrom : message.messageTo}
                            width='100%'
                            height='auto'
                          />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
            {selectedChat && (
              <div className=' p-0 '>
                <div className='row'>
                  <div className='col'>
                    <div className=' bg-light p-3'>
                      <form
                        className='needs-validation'
                        name='chat-form'
                        id='chat-form'
                        onSubmit={handleSubmit(sendSubmit)}
                      >
                        <div className='row'>
                          <div
                            className='col mb-2 mb-sm-0'
                            onChange={(e) => {
                              setSendSmsBody(e.target.value);
                            }}
                          >
                            <input
                              type='text'
                              className='form-control border-0'
                              value={sendSmsBody}
                              required
                              {...register('sendSmsBody')}
                              placeholder='Enter your text'
                            />
                          </div>
                          <div className='col-sm-auto'>
                            <div className='btn-group'>
                              <div className='d-grid'>
                                <button
                                  disabled={sendSmsBody === ''}
                                  type='submit'
                                  className='btn btn-success chat-send'
                                >
                                  <i className='uil uil-message' />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*   SEND MSG MODAL
       ********************************************* */}
      <Modal show={showSendModal} onHide={() => setShowSendModal(false)}>
        <Modal.Body>
          <div>
            <h3 className='mb-3'>SEND MESSAGE</h3>
            <form onSubmit={handleSubmit(submitForm, onError)}>
              <div className='form-group mb-3'>
                <label className='form-label'>Contact Number</label>
                <div className='d-flex'>
                  <input
                    placeholder='Enter Phone Number'
                    type='tel'
                    className={`form-control ${
                      phone == null || phone === undefined || phone === ''
                        ? 'border-danger border'
                        : ''
                    }`}
                    required
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  <button
                    className='btn btn-sm btn-success ms-1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Contacts
                  </button>
                  <div className='dropdown-menu dropdown-menu-end'>
                    <a className='dropdown-item' href='#'>
                      Contact 1
                    </a>
                    <a className='dropdown-item' href='#'>
                      Contact 2
                    </a>
                    <a className='dropdown-item' href='#'>
                      Contact 3
                    </a>
                  </div>
                </div>

                <div>
                  <small className='text-danger'>
                    {phone == null || phone === undefined || phone === ''
                      ? 'Phone is required'
                      : ''}
                  </small>
                </div>
                {formError && formError.contactNumber && (
                  <p className='text-danger'>{`${formError.contactNumber.message}`}</p>
                )}
              </div>

              <div className='mb-3' onChange={(e) => setSmsBody(e.target.value)}>
                <label className='form-label'>SMS BODY</label>
                <textarea
                  className='form-control'
                  rows='5'
                  required
                  value={smsBody}
                  placeholder='Enter Your Message Here'
                  {...register('smsBody')}
                />
              </div>
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary mx-1'
                  onClick={() => {
                    setPhone('');
                    setSmsBody('');
                    setShowSendModal(false);
                    setFormError({});
                  }}
                >
                  Close
                </button>
                <button type='submit' className='btn btn-primary'>
                  Send
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <div
        id='danger-alert-modal'
        className='modal fade'
        tabIndex='-1'
        role='dialog'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-sm '>
          <div className='modal-content modal-filled bg-danger'>
            <div className='modal-body p-4'>
              <div className='text-center'>
                <i className='ri-close-circle-line h1'></i>
                <h4 className='mt-2'>Confirm Delete!</h4>
                <p className='mt-3'>Do You want to delete ?</p>
                <button type='button' className='btn btn-light my-2 mx-2' data-bs-dismiss='modal'>
                  Cancel
                </button>
                <button
                  type='button'
                  className='btn btn-outline-light my-2 '
                  data-bs-dismiss='modal'
                  onClick={() => {
                    deleteChat(selectedDelete);
                  }}
                >
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmsManager;
